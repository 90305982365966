import {
  delRequest,
  postRequest,
  putRequest,
  getRequest,
} from "../connection/networkPyxiz";
import store from "../store";

const pollsApi = {
  list: "poll",
  choose: "poll/choose",
  option: "poll/option",
};
const timelineApis = { posts: "page/post" };

export const getPostsService = async ({
  pageId,
  limit,
  page,
  categoryId,
  type,
  text,
  sortBy,
  mediaType,
  selectedDay,
  postTypeId,
  pollTypeId,
  fileTypeId,
  eventTypeId,
  contactPosts,
  myId,
  pollCategoryId,
  eventCategoryId,
  startDate,
  dateWithIn,
  id,
}) => {
  try {
    const token = localStorage.getItem("token");
    console.log(id);

    const {
      data: {
        data,
        meta: { thisPage, allPages, count },
      },
    } = await getRequest({
      token,
      query: {
        pageId,
        limit,
        page,
        categoryId,
        type,
        text,
        sortBy,
        mediaType,
        selectedDay,
        postTypeId,
        pollTypeId,
        fileTypeId,
        eventTypeId,
        contactPosts,
        myId,
        pollCategoryId,
        eventCategoryId,
        startDate,
        dateWithIn,
        id,
      },
      endPoint: timelineApis.posts,
    });

    return {
      data,
      thisPage: parseInt(thisPage),
      allPages: parseInt(allPages),
      hasMore: data?.length === limit,
      count,
    };
  } catch (error) {
    const sError =
      error?.response?.data?.error?.message || "Failed to get data";
    return { error: sError };
  }
};

export const createNewPollService = async ({ data: sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await postRequest({ body: sendData, endPoint: pollsApi.list, token });
    data = { ...data.event, ...data };
    delete data.event;
    data.creator = store.getState()?.auth?.user;
    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};

export const answerPollService = async ({ pollId, optionId, questionId }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await putRequest({
      body: { pollId, optionId, questionId },
      endPoint: pollsApi.choose,
      token,
    });
    data = { ...data.event, ...data };
    delete data.event;
    data.creator = store.getState()?.auth?.user;
    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};

export const addPollOptionImageService = async ({ data: sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await putRequest({
      body: sendData,
      endPoint: pollsApi.option,
      token,
    });
    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};

export const deleteMyPollChoiceService = async ({ pollId, questionId }) => {
  try {
    const token = localStorage.getItem("token");
    let {
      data: { data, message },
    } = await delRequest({
      endPoint: pollsApi.choose,
      token,
      query: { pollId, questionId },
    });
    data = { ...data.event, ...data };
    delete data.event;
    data.creator = store.getState()?.auth?.user;
    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};
