import { Box, Avatar } from "@mui/material";

function SeekerBar({
  handleWaveformSeek,
  handleWaveformDragStart,
  handleWaveformDrag,
  handleWaveformDragEnd,
  currentTime,
  videoRef,
  isVideoPaused,
}) {
  const min = 0;
  const max = videoRef.current ? videoRef.current.duration : 0;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box
        id="waveform"
        sx={{
          position: "relative",
          width: "100%",
          backgroundColor: "#eeeeee",
          pointerEvents: isVideoPaused ? "none" : "all",
        }}
        onClick={handleWaveformSeek}
        onMouseDown={handleWaveformDragStart}
        onMouseMove={handleWaveformDrag}
        onMouseUp={handleWaveformDragEnd}
        onMouseLeave={handleWaveformDragEnd}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: `calc(${((currentTime - min) * 100) / (max - min)}%)`,
            transform: "translateX(-50%)",
            color: "#EA5069",
            fontSize: "0.8em",
            fontWeight: "bold",
            zIndex: "10",
            backgroundColor: "#eeeeee",
            fontFamily: "SofiaProMedium",
          }}
        >
          {currentTime.toFixed(1)}s
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "20px",
          backgroundColor: "white",
          backgroundColor: "#25AAE1",
          width: "100%",
        }}
      >
        <Avatar
          src="/images/yara.png"
          alt="Moving Image"
          style={{
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            backgroundColor: "white",
            left: `calc(${((currentTime - min) * 100) / (max - min)}%)`,
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Box>
    </Box>
  );
}
export default SeekerBar;
