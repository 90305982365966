function Emoji5() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.095 32.095">
      <g id="layer1" transform="translate(0)">
        <ellipse
          id="path2430"
          cx="16.047"
          cy="16.047"
          rx="16.047"
          ry="16.047"
          transform="translate(0 0)"
          fill="#ffd42a"
        />
        <path
          id="path2471"
          d="M5.627,282.614a16.037,16.037,0,1,0,24.847,19.668q-.444.42-.918.806-.628.5-1.3.933a16.047,16.047,0,0,1-7.536,2.5q-.566.04-1.134.04c-.533,0-1.068-.027-1.6-.08a16.042,16.042,0,0,1-8.738-3.694q-.614-.517-1.174-1.093a16.047,16.047,0,0,1-1.059-1.206q-.5-.628-.933-1.3t-.8-1.392q-.364-.715-.656-1.464t-.505-1.523q-.215-.773-.351-1.565t-.194-1.593q-.04-.566-.04-1.135,0-.8.08-1.6t.239-1.586a16.042,16.042,0,0,1,.4-1.555q.237-.767.55-1.507a16.036,16.036,0,0,1,.7-1.444q.064-.106.129-.21Z"
          transform="translate(-0.473 -278.33)"
          fill="#ffbc2a"
        />
        <ellipse
          id="path2448"
          cx="4.686"
          cy="4.686"
          rx="4.686"
          ry="4.686"
          transform="translate(0.911 13.065)"
          fill="#f55"
        />
        <ellipse
          id="circle2473"
          cx="4.686"
          cy="4.686"
          rx="4.686"
          ry="4.686"
          transform="translate(21.809 13.065)"
          fill="#f55"
        />
        <path
          id="path2553"
          d="M3.662,286.049s3.436-3.247,6.683,0"
          transform="translate(2.691 -276.354)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="path2555"
          d="M10.04,286.049s3.436-3.247,6.683,0"
          transform="translate(9.02 -276.354)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="path1105"
          d="M4.147,289.673s.751,4.957,8.5,4.957"
          transform="translate(3.173 -271.327)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="path1107"
          d="M16.918,289.673s-.751,4.957-8.5,4.957"
          transform="translate(7.408 -271.327)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default Emoji5;
