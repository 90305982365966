export default function Alarm() {
  return (
    <svg id="alarm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.4 46.33">
      <g id="Group_65395" data-name="Group 65395">
        <path
          id="Path_15813"
          data-name="Path 15813"
          d="M24.071,60.96,21.8,64.447a1.463,1.463,0,0,0,.431,2.025,1.469,1.469,0,0,0,2.025-.431l2.215-3.407a21.137,21.137,0,0,0,21.639-.007l2.208,3.407a1.464,1.464,0,0,0,2.456-1.594l-2.259-3.487a21.2,21.2,0,1,0-26.449.007ZM37.3,26.133A18.276,18.276,0,1,1,19.019,44.409,18.292,18.292,0,0,1,37.3,26.133Z"
          transform="translate(-16.095 -20.376)"
          stroke="none"
        />
        <path
          id="Path_15814"
          data-name="Path 15814"
          d="M184.119,103.2a1.463,1.463,0,1,0,1.93-2.2L180.7,96.313V84.551a1.462,1.462,0,0,0-2.924,0V96.979a1.474,1.474,0,0,0,.5,1.1Z"
          transform="translate(-158.036 -72.945)"
          stroke="none"
        />
        <path
          id="Path_15815"
          data-name="Path 15815"
          d="M29.481,7.607a1.455,1.455,0,0,0,1.045-.439,24.424,24.424,0,0,1,5.856-4.415A1.464,1.464,0,0,0,35,.172a27.09,27.09,0,0,0-6.572,4.949A1.465,1.465,0,0,0,28.45,7.19a1.5,1.5,0,0,0,1.031.417Z"
          transform="translate(-26.556 0)"
          stroke="none"
        />
        <path
          id="Path_15816"
          data-name="Path 15816"
          d="M277.348,2.753A24.093,24.093,0,0,1,283.2,7.169a1.463,1.463,0,0,0,2.091-2.047A27.13,27.13,0,0,0,278.722.173a1.462,1.462,0,1,0-1.374,2.58Z"
          transform="translate(-244.773 -0.001)"
          stroke="none"
        />
      </g>
    </svg>
  );
}
