function Eye() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.301 18.867">
      <path
        id="Icon_awesome-eye"
        data-name="Icon awesome-eye"
        d="M28.13,13.216A15.759,15.759,0,0,0,14.151,4.5,15.762,15.762,0,0,0,.171,13.217a1.589,1.589,0,0,0,0,1.434,15.759,15.759,0,0,0,13.98,8.716,15.762,15.762,0,0,0,13.98-8.717A1.589,1.589,0,0,0,28.13,13.216Zm-13.98,7.793a7.075,7.075,0,1,1,7.075-7.075A7.075,7.075,0,0,1,14.151,21.009Zm0-11.792a4.683,4.683,0,0,0-1.244.186A2.351,2.351,0,0,1,9.62,12.69a4.706,4.706,0,1,0,4.531-3.473Z"
        transform="translate(0 -4.5)"
        fill="#e2e2e2"
      />
    </svg>
  );
}

export default Eye;
