import {
  Avatar,
  Box,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowDown from "../SVGs/ArrowDown";
import MenuIcon from "../SVGs/MenuIcon";
import SearchIcon from "../SVGs/SearchIcon";
import NotificationBell from "../SVGs/NotificationBell";
import DrawerMenu from "@mui/icons-material/Menu";

function Header({ handleDrawerToggle }) {
  return (
    <Toolbar
      sx={{ bgcolor: "white", position: "sticky", top: 0, zIndex: "100" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          width: "100%",
          maxWidth: "100%",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: "none", sm: "1em" },
            flexWrap: "wrap",
          }}
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { sm: "none" },
              color: "#C8C8C8",
              "&:hover": {
                color: "#25AAE1",
              },
            }}
          >
            <DrawerMenu />
          </IconButton>
          <IconButton
            sx={{
              transition: "0.5s all",
              cursor: "pointer",
              fill: "#C8C8C8",
              "&:hover": {
                fill: "#25AAE1",
                backgroundColor: "transparent",
              },
            }}
          >
            <ArrowDown />
          </IconButton>

          <IconButton
            sx={{
              transition: "0.5s all",
              fill: "#C8C8C8",
              stroke: "#C8C8C8",
              "&:hover": {
                fill: "#25AAE1",
                stroke: "#25AAE1",
                backgroundColor: "transparent",
              },
            }}
          >
            <SearchIcon />
            <MenuIcon />
          </IconButton>
          <TextField
            placeholder="Search for TV services"
            size="small"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderWidth: 0,
                },
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: "1px solid #25AAE1",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: "2px solid #25AAE1",
                },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: "0.5em", sm: "1em" },
            flexWrap: "wrap",
          }}
        >
          <IconButton
            sx={{
              transition: "0.5s all",
              fill: "#C8C8C8",
              "&:hover": {
                fill: "#25AAE1",
                backgroundColor: "transparent",
              },
            }}
          >
            <NotificationBell />
          </IconButton>
          <Avatar
            src="/images/yara.png"
            alt=""
            sx={{
              cursor: "pointer",
              width: { xs: "30px", sm: "50px" },
              height: { xs: "30px", sm: "50px" },
            }}
          />
          <Typography
            variant="body1"
            sx={{
              color: "#323232",
              pointerEvents: "none",
            }}
          >
            Randa Tarek
          </Typography>
          <IconButton
            sx={{
              fill: "#323232",
              transition: "0.5s all",
              "&:hover": {
                fill: "#25AAE1",
                backgroundColor: "transparent",
              },
            }}
          >
            <ArrowDown />
          </IconButton>
        </Box>
      </Box>
    </Toolbar>
  );
}

export default Header;
