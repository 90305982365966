function InteractiveBook() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" stroke="none">
      <g
        id="_20_Media_literacy"
        data-name="20 Media literacy"
        transform="translate(0 -4)"
      >
        <g id="Group_65352" data-name="Group 65352" transform="translate(0 4)">
          <path
            id="Path_15772"
            data-name="Path 15772"
            d="M15.75,42.823h-.5v-1.75a.25.25,0,0,0-.25-.25h-.45a6,6,0,0,1-1.511-.194l-3.1-.808A1.747,1.747,0,0,0,8,40.614a1.747,1.747,0,0,0-1.942-.794l-3.1.808a6,6,0,0,1-1.511.194H1a.25.25,0,0,0-.25.25v1a.25.25,0,0,0,.5,0v-.75h.2a6.492,6.492,0,0,0,1.638-.21l3.1-.808A1.25,1.25,0,0,1,7.75,41.514v.559a.25.25,0,0,0,.5,0v-.559A1.25,1.25,0,0,1,9.816,40.3l3.1.808a6.489,6.489,0,0,0,1.637.21h.2v1.5H.25a.25.25,0,0,0-.25.25v1a.25.25,0,0,0,.25.25H7.1a1.06,1.06,0,0,0,1.8,0h6.85a.25.25,0,0,0,.25-.25v-1A.25.25,0,0,0,15.75,42.823Zm-.25,1H8.75a.25.25,0,0,0-.225.138l-.025.053a.559.559,0,0,1-1,0l-.026-.053a.25.25,0,0,0-.224-.138H.5v-.5h15Z"
            transform="translate(0 -30.823)"
          />
          <path
            id="Path_15773"
            data-name="Path 15773"
            d="M4.75,11h3.5a.25.25,0,0,0,0-.5H4.75a.25.25,0,0,1-.25-.25V4.75a.25.25,0,0,1,.25-.25h7.5a.25.25,0,0,1,.25.25.25.25,0,0,0,.5,0A.75.75,0,0,0,12.25,4H4.75A.75.75,0,0,0,4,4.75v5.5A.75.75,0,0,0,4.75,11Z"
            transform="translate(-3 -4)"
          />
          <path
            id="Path_15774"
            data-name="Path 15774"
            d="M24,10.75v5.5a.75.75,0,0,0,.75.75h7.5a.75.75,0,0,0,.75-.75v-5.5a.75.75,0,0,0-.75-.75h-7.5A.75.75,0,0,0,24,10.75Zm8.5,0v5.5a.25.25,0,0,1-.25.25h-7.5a.25.25,0,0,1-.25-.25v-5.5a.25.25,0,0,1,.25-.25h7.5A.25.25,0,0,1,32.5,10.75Z"
            transform="translate(-18 -8.5)"
          />
          <path
            id="Path_15775"
            data-name="Path 15775"
            d="M37.119,21.462a.25.25,0,0,0,.243.011l2-1a.25.25,0,0,0,0-.448l-2-1A.25.25,0,0,0,37,19.25v2a.25.25,0,0,0,.119.213Zm.381-1.808,1.191.6-1.191.6Z"
            transform="translate(-27.75 -15.25)"
          />
          <path
            id="Path_15776"
            data-name="Path 15776"
            d="M9,10A1,1,0,1,0,8,9,1,1,0,0,0,9,10ZM9,8.5a.5.5,0,1,1-.5.5A.5.5,0,0,1,9,8.5Z"
            transform="translate(-6 -7)"
          />
          <path
            id="Path_15777"
            data-name="Path 15777"
            d="M8.24,18.75A.248.248,0,0,0,8.4,18.7l1.075-.86.842.842a.25.25,0,0,0,.391-.048l.75-1.25a.25.25,0,1,0-.429-.258l-.585.975-.774-.775a.25.25,0,0,0-.333-.018l-1.25,1a.25.25,0,0,0,.157.447Z"
            transform="translate(-5.99 -13.75)"
          />
        </g>
      </g>
    </svg>
  );
}

export default InteractiveBook;
