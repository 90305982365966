import { InteractiveVideoAction } from "./InteractiveVideoAction";

export const InteractiveVideo = {
  initialValue: () => initialValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data) => toMap(data),
};

const initialValue = () => ({
  index: "",
  url: "",
  loop: false,
  width: "",
  height: "",
  actions: [],
});

const fromJSON = (data) => ({
  index: data?.index,
  url: data?.url,
  isLoop: data?.loop,
  width: data?.width,
  height: data?.height,
  actions: data?.actions?.map((e) =>
    InteractiveVideoAction.fromJSON(e, data?.width, data?.height)
  ),
});

const toMap = (data) => ({
  index: data?.index,
  url: data?.url,
  loop: data?.isLoop,
  width: data?.width,
  height: data?.height,
  actions: data?.actions,
});
