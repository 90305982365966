function fullStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.229"
      height="20.318"
      viewBox="0 0 21.229 20.318"
    >
      <path
        id="Icon_awesome-star"
        data-name="Icon awesome-star"
        d="M10.917.706,8.326,5.959l-5.8.845a1.27,1.27,0,0,0-.7,2.166L6.02,13.058l-.992,5.773A1.269,1.269,0,0,0,6.87,20.169l5.186-2.726,5.186,2.726a1.27,1.27,0,0,0,1.841-1.337l-.992-5.773,4.194-4.087a1.27,1.27,0,0,0-.7-2.166l-5.8-.845L13.194.706a1.271,1.271,0,0,0-2.278,0Z"
        transform="translate(-1.441 0.001)"
      />
    </svg>
  );
}

export default fullStar;
