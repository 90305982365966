import { CircularProgress, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";

export default function UserAvatar({
  src,
  alt,
  type = "user",
  color,
  sx = {},
  onClick,
}) {
  return (
    <Tooltip title={alt || ""} placement="top">
      <Avatar
        src={src}
        alt={alt || ""}
        sx={{
          backgroundColor: color,
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          ...sx,
        }}
        imgProps={{ loading: "lazy" }}
      >
        {String(alt)[0]}
      </Avatar>
    </Tooltip>
  );
}
