function TranslateBtn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25 "
      viewBox="0 0 21.528 21.528"
      fill="none"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="path252"
            d="M0-682.665H21.528v21.528H0Z"
            transform="translate(0 682.665)"
          />
        </clipPath>
      </defs>
      <g id="g246" transform="translate(0 682.665)">
        <g id="g248" transform="translate(0 -682.665)">
          <g id="g250" transform="translate(0 0)" clipPath="url(#clip-path)">
            <g id="g256" transform="translate(8.325 7.989)">
              <path
                id="path258"
                d="M0-167.921v4.035a1.345,1.345,0,0,0,1.345,1.346h6.1l2.775,2.271v-2.271h1.219a1.346,1.346,0,0,0,1.346-1.346v-8.157a1.346,1.346,0,0,0-1.346-1.346H5.132"
                transform="translate(0 173.389)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g260" transform="translate(12.504 10.936)">
              <path
                id="path262"
                d="M-135.884-154.845l-2.212-5.042-2.212,5.042"
                transform="translate(140.309 159.888)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g264" transform="translate(12.803 14.278)">
              <path
                id="path266"
                d="M-117.517,0h-3.827"
                transform="translate(121.344)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g268" transform="translate(0.42 0.42)">
              <path
                id="path270"
                d="M-26.877-99.921a6.488,6.488,0,0,0-.875,3.265,6.488,6.488,0,0,0,1.245,3.832l-.8,1.765,1.989-.52h0a6.49,6.49,0,0,0,4.088,1.441,6.518,6.518,0,0,0,6.518-6.518,6.518,6.518,0,0,0-6.518-6.518,6.488,6.488,0,0,0-3.257.871"
                transform="translate(27.752 103.174)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g272" transform="translate(2.329 2.329)">
              <path
                id="path274"
                d="M0,0H0"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g276" transform="translate(4.975 6.184)">
              <path
                id="path278"
                d="M0,0H4.272"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g280" transform="translate(4.631 4.011)">
              <path
                id="path282"
                d="M0-179.819H0a4.4,4.4,0,0,0,2.283-3.856v-2"
                transform="translate(0 185.674)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g284" transform="translate(6.914 4.011)">
              <path
                id="path286"
                d="M-70.118-179.819h0a4.4,4.4,0,0,1-2.283-3.856v-2"
                transform="translate(72.401 185.674)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TranslateBtn;
