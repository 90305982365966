function Challenge() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.553 19"
      stroke="none"
    >
      <g id="challenge" transform="translate(-40.75 -23)">
        <path
          id="Path_15756"
          data-name="Path 15756"
          d="M63.869,265.256a1.211,1.211,0,0,1-1.209-1.21v-5.31a.326.326,0,1,1,.652,0v5.31a.557.557,0,1,0,1.115,0V258.8a.326.326,0,1,1,.652,0v5.25a1.21,1.21,0,0,1-1.21,1.21Z"
          transform="translate(-21.017 -225.812)"
        />
        <path
          id="Path_15757"
          data-name="Path 15757"
          d="M46.43,98.122A1.223,1.223,0,0,1,45.207,96.9V93.951H42.6a1.6,1.6,0,0,1-1.6-1.6V88.7a.976.976,0,0,1,.974-.975H45.6a2.033,2.033,0,0,0,1.016-.272l1.91-1.1a1,1,0,1,1,1,1.738l-1.9,1.095a2.9,2.9,0,0,1-1.451.388H45.118v2.365h1.687a.849.849,0,0,1,.848.848V96.9A1.223,1.223,0,0,1,46.43,98.122Zm-4.456-9.744a.323.323,0,0,0-.322.322v3.652a.947.947,0,0,0,.946.946h2.936a.326.326,0,0,1,.326.326V96.9A.571.571,0,0,0,47,96.9V92.787a.2.2,0,0,0-.2-.2H44.792a.326.326,0,0,1-.326-.326V89.248a.326.326,0,0,1,.326-.326H46.18a2.253,2.253,0,0,0,1.124-.3l1.9-1.1a.351.351,0,0,0-.351-.608l-1.91,1.1a2.686,2.686,0,0,1-1.342.36Z"
          transform="translate(-0.24 -60.64)"
        />
        <path
          id="Path_15758"
          data-name="Path 15758"
          d="M42.889,26.817A1.909,1.909,0,1,1,44.8,24.909,1.909,1.909,0,0,1,42.889,26.817Zm0-3.165a1.256,1.256,0,1,0,1.256,1.256A1.256,1.256,0,0,0,42.889,23.652Z"
          transform="translate(-0.221 0)"
        />
        <path
          id="Path_15759"
          data-name="Path 15759"
          d="M44.758,382.526H41.076a.326.326,0,0,1-.326-.326v-3.873a.326.326,0,0,1,.326-.326h.893a.326.326,0,0,1,0,.652H41.4v3.221h3.029v-3.221h-.7a.326.326,0,0,1,0-.652h1.022a.326.326,0,0,1,.326.326V382.2a.327.327,0,0,1-.327.326Z"
          transform="translate(0 -340.526)"
        />
        <path
          id="Path_15760"
          data-name="Path 15760"
          d="M135.058,357.26h-3.681a.326.326,0,0,1-.326-.326v-4.948a.326.326,0,0,1,.326-.326h.667a.326.326,0,0,1,0,.652h-.34v4.3h3.029v-4.3h-1.155a.326.326,0,0,1,0-.652h1.484a.326.326,0,0,1,.326.326v4.948a.326.326,0,0,1-.328.326Z"
          transform="translate(-86.618 -315.26)"
        />
        <path
          id="Path_15761"
          data-name="Path 15761"
          d="M225.357,310.862h-3.681a.326.326,0,0,1-.326-.326v-6.919a.326.326,0,0,1,.326-.326h3.681a.326.326,0,0,1,.326.326v6.919A.326.326,0,0,1,225.357,310.862ZM222,310.209h3.029v-6.267H222Z"
          transform="translate(-173.236 -268.862)"
        />
        <path
          id="Path_15762"
          data-name="Path 15762"
          d="M315.648,266.776h-3.682a.326.326,0,0,1-.326-.326v-8.793a.326.326,0,0,1,.326-.326h3.682a.326.326,0,0,1,.326.326v8.793A.326.326,0,0,1,315.648,266.776Zm-3.355-.652h3.029v-8.141h-3.029Z"
          transform="translate(-259.845 -224.776)"
        />
        <path
          id="Path_15763"
          data-name="Path 15763"
          d="M357.116,40.1a.326.326,0,0,1-.326-.326V30.516a.326.326,0,0,1,.652,0v9.261A.326.326,0,0,1,357.116,40.1Z"
          transform="translate(-303.154 -6.897)"
        />
        <path
          id="Path_15764"
          data-name="Path 15764"
          d="M357.116,35.475a.326.326,0,0,1-.326-.326V30.514a.326.326,0,0,1,.49-.283l4.014,2.317a.326.326,0,0,1,0,.565l-4.014,2.317A.325.325,0,0,1,357.116,35.475Zm.326-4.4v3.506l3.036-1.753Z"
          transform="translate(-303.154 -6.895)"
        />
      </g>
    </svg>
  );
}

export default Challenge;
