import { Box, Button, Typography } from "@mui/material";
import PlusOne from "./PlusOne";
import React from "react";
import { Tada } from "./../../../config/animations";
import useIsWidthUp from "../../../hook/useIsWidthUp";

export default function PollAnswer({
  color = "secondary",
  onClick,
  variant = "contained",
  textVariant = "body2",
  biggerTextVariant = "h6",
  subTextVariant = "caption",
  size = "medium",
  title,
  className = "",
  style,
  type,
  disabled = false,
  votes = 0,
  isWinner,
  isTimeUp,
  isSelected,
  width,
}) {
  const isLargeScreen = useIsWidthUp("lg");
  let animate = isTimeUp && isWinner;
  let elevate = isLargeScreen && animate;
  let loss = isTimeUp && !isWinner;

  let makeBigger = isSelected || (isWinner && isTimeUp);

  return (
    <Box
      style={{
        position: "relative",
      }}
    >
      {[...Array(votes).keys()]?.map((_, i) => (
        <PlusOne key={i} />
      ))}
      <Tada animate={animate} duration={1.5}>
        <Button
          type={type}
          sx={{
            // position: "absolute",
            px: {
              xs: makeBigger ? 5 : 3,
              md: makeBigger ? 8 : 6,
            },
            mx: 1,
            py: {
              xs: makeBigger ? 1.5 : 1,
              md: 2,
            },
            borderRadius: 10,
            transition: "all .4s",
            ...style,
            // "&:hover": { backgroundColor: "transparent" }
          }}
          variant={variant}
          color={animate || isSelected ? "primary" : color}
          size={size}
          onClick={onClick}
          disabled={loss || disabled}
        >
          <Box>
            <Typography variant={textVariant} color="inherit">
              {title}
            </Typography>
            <Typography
              className="mt-2"
              variant={subTextVariant}
              color="inherit"
            >
              ( {votes} )
            </Typography>
          </Box>
        </Button>
      </Tada>
    </Box>
  );
}
