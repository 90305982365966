import { Grid, Typography, Box, LinearProgress } from "@mui/material";

import {
  answerPollAction,
  deleteChoiceAction,
} from "../../../store/actions/poll";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import Poll from "./Polls";

export function LinearProgressWithLabel({
  color = "#ECECEC",
  height = 56,
  ...props
}) {
  return (
    <Box display="flex">
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            width: 1,
            height,
            borderRadius: "8px",
            border: `1px solid ${color}`,
            "&.MuiLinearProgress-colorPrimary": {
              backgroundColor: "primary.white",
            },
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: color,
            },
          }}
        />
      </Box>
      {!props?.removePercent && (
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default function PostType({
  type,
  postId,
  userId,
  creator,
  forum,
  searchPost,
}) {
  let dispatch = useDispatch();

  let choosePollOption = useCallback(
    ({ pollId, optionId, questionId }) => {
      answerPollAction({ pollId, optionId, questionId, postId })(dispatch);
    },
    [dispatch, postId]
  );

  const deleteChoice = useCallback(
    ({ pollId, questionId }) => {
      deleteChoiceAction({ pollId, questionId, postId })(dispatch);
    },
    [dispatch, postId]
  );

  return (
    <>
      <Grid item xs={12} sx={{ textAlign: "start", mb: 1 }}>
        <Typography variant="body2" color="text.primary" fontWeight="bold">
          {type?.poll?.title}
        </Typography>
      </Grid>
      <Poll
        poll={type?.poll}
        choose={choosePollOption}
        userId={userId}
        creator={creator}
        deleteChoice={deleteChoice}
        postId={postId}
      />
    </>
  );

}
