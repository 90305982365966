function SendBtn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 29.08 29.08"
      fill="none"
    >
      <g
        id="Icon_feather-send"
        data-name="Icon feather-send"
        transform="translate(1 1.414)"
      >
        <path
          id="Path_32"
          data-name="Path 32"
          d="M20.393,2.5,13.75,9.143"
          transform="translate(6.273 -2.5)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M29.166,2.5,19.833,29.166l-5.333-12-12-5.333Z"
          transform="translate(-2.5 -2.5)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default SendBtn;
