export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const INTERACTIVE_LOADING = "INTERACTIVE_LOADING";
export const LOAD_INTERACTIVE_ERROR = "LOAD_INTERACTIVE_ERROR";
export const LOAD_INTERACTIVE = "LOAD_INTERACTIVE";

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const SET_SESSION = "SET_SESSION";

export const CHOOSE_POLL_OPTION = "CHOOSE_POLL_OPTION";
export const DELETE_CHOICE_POLL_OPTION = "DELETE_CHOICE_POLL_OPTION";
export const CHOOSE_MESSAGE_POLL_OPTION = "CHOOSE_MESSAGE_POLL_OPTION";
export const UPDATE_POLL_OPTION = "UPDATE_POLL_OPTION";

export const LOAD_POSTS = "LOAD_POSTS";
export const LOAD_POSTS_ERROR = "LOAD_POSTS_ERROR";
export const LOAD_POSTS_LOADING = "LOAD_POSTS_LOADING";
