import styled, { css, keyframes } from "styled-components";
import { tada, pulse, fadeInUp } from "react-animations";

export const Tada = styled.div`
  ${({ animate, duration = 1 }) =>
    !animate
      ? ""
      : css`
          animation: ${duration}s ${keyframes`${tada}`};
        `}
`;

export const Pulse = styled.div`
  ${({ animate, duration = 1 }) =>
    !animate
      ? ""
      : css`
          animation: ${duration}s infinite ${keyframes`${pulse}`};
        `}
`;

export const FadeInUp = styled.div`
  ${({ animate, duration = 0.5 }) =>
    !animate
      ? ""
      : css`
          animation: ${duration}s ${keyframes`${fadeInUp}`};
        `}
`;
