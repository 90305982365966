function Emoji4() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.095 32.095">
      <g id="layer1" transform="translate(0)">
        <ellipse
          id="circle2930"
          cx="16.047"
          cy="16.047"
          rx="16.047"
          ry="16.047"
          transform="translate(0 0)"
          fill="#ffd42a"
        />
        <path
          id="path2932"
          d="M23.176,318.388a16.037,16.037,0,1,0,24.847,19.668q-.444.42-.918.806a16.034,16.034,0,0,1-1.3.933q-.676.434-1.392.8a16.052,16.052,0,0,1-6.144,1.706q-.566.04-1.134.04-.8,0-1.6-.08a16.036,16.036,0,0,1-3.141-.637q-.767-.237-1.507-.55t-1.444-.7q-.7-.385-1.367-.838t-1.277-.971q-.614-.517-1.174-1.093t-1.059-1.206q-.5-.628-.933-1.3t-.8-1.392q-.364-.715-.656-1.464t-.505-1.523q-.215-.773-.351-1.565t-.194-1.593q-.04-.566-.04-1.135a16.044,16.044,0,0,1,.08-1.6q.08-.8.239-1.586t.4-1.555q.237-.767.55-1.507t.7-1.444q.064-.106.129-.21Z"
          transform="translate(-18.021 -314.104)"
          fill="#ffbc2a"
        />
        <path
          id="path3001"
          d="M21.582,327.12s2.989-6.574,14.376,0"
          transform="translate(-14.488 -308.34)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <ellipse
          id="ellipse3003"
          cx="1.409"
          cy="1.389"
          rx="1.409"
          ry="1.389"
          transform="translate(22.304 6.415)"
          fill="#1a1a1a"
        />
        <ellipse
          id="ellipse3005"
          cx="1.409"
          cy="1.389"
          rx="1.409"
          ry="1.389"
          transform="translate(11.655 6.415)"
          fill="#1a1a1a"
        />
        <path
          id="path3007"
          d="M28.2,326.569a6.237,6.237,0,0,0-4.565.118"
          transform="translate(-12.449 -306.38)"
          fill="none"
          stroke="#ffbc2a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
      </g>
    </svg>
  );
}

export default Emoji4;
