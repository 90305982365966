import { createReducer } from "@reduxjs/toolkit";
import {
  INTERACTIVE_LOADING,
  LOAD_INTERACTIVE,
  LOAD_INTERACTIVE_ERROR,
} from "../actions/types";

const initialState = {
  data: {},
  error: false,
  loading: false,
};

const interactiveReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(INTERACTIVE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(LOAD_INTERACTIVE, (state, { payload }) => {
      state.data = payload;

      state.error = false;
      state.loading = false;
    })
    .addCase(LOAD_INTERACTIVE_ERROR, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
});

export default interactiveReducer;
