function Ask() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" fill="none">
      <defs>
        <clipPath id="clip-path">
          <path
            id="path1587"
            d="M0-682.665H19v19H0Z"
            transform="translate(0 682.665)"
          />
        </clipPath>
      </defs>
      <g id="g1581" transform="translate(0 682.665)">
        <g id="g1583" transform="translate(0 -682.665)">
          <g id="g1585" clipPath="url(#clip-path)">
            <g id="g1591" transform="translate(7.273 5.9)">
              <path
                id="path1593"
                d="M-162.026,0h5.492A1.041,1.041,0,0,1-155.5,1.039v11.5l-3.4-2.078h-6.736a1.041,1.041,0,0,1-1.039-1.039V5.917"
                transform="translate(166.665)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g1595" transform="translate(0.557 0.557)">
              <path
                id="path1597"
                d="M-198.322,0a5.678,5.678,0,0,1,5.678,5.678,5.678,5.678,0,0,1-5.678,5.678,5.651,5.651,0,0,1-2.835-.758L-204,11.648l1.011-2.736A5.651,5.651,0,0,1-204,5.678,5.678,5.678,0,0,1-198.322,0Z"
                transform="translate(203.999 0)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g1599" transform="translate(4.472 4.694)">
              <path
                id="path1601"
                d="M0-46.664,1.466-45.2l2.8-2.8"
                transform="translate(0 48)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g1603" transform="translate(11.003 9.314)">
              <path
                id="path1605"
                d="M0,0,3.674,3.674"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g1607" transform="translate(11.003 9.314)">
              <path
                id="path1609"
                d="M-128.326,0-132,3.674"
                transform="translate(132)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Ask;
