function BroadcastRadio() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 19.048">
      <g id="broadcast" transform="translate(-40.96)">
        <g
          id="Group_65350"
          data-name="Group 65350"
          transform="translate(40.96)"
          stroke="none"
        >
          <g id="Group_65349" data-name="Group 65349" transform="translate(0)">
            <path
              id="Path_15750"
              data-name="Path 15750"
              d="M48.96,0a8,8,0,0,0-4.824,14.376.381.381,0,0,0,.459-.609,7.238,7.238,0,1,1,8.73,0,.381.381,0,1,0,.459.609A8,8,0,0,0,48.96,0Z"
              transform="translate(-40.96)"
            />
            <path
              id="Path_15751"
              data-name="Path 15751"
              d="M151.773,176.283l-2.007-5.616c0-.008,0-.015-.008-.022l-1.171-3.277a1.9,1.9,0,1,0-1.966,0l-1.171,3.277c0,.007,0,.014-.008.022l-2.007,5.616a.437.437,0,0,0-.008.053.374.374,0,0,0-.012.086.367.367,0,0,0,.014.072.426.426,0,0,0,.018.07.4.4,0,0,0,.048.072c.011.014.017.032.03.046a.016.016,0,0,1,.007,0,.394.394,0,0,0,.063.042.36.36,0,0,0,.065.04l.007,0a.394.394,0,0,0,.128.022.381.381,0,0,0,.191-.059c.014-.009.027-.017.04-.027s.027-.014.039-.025l3.54-3.541,3.54,3.541c.011.011.026.015.038.025l.04.027a.381.381,0,0,0,.191.059.394.394,0,0,0,.128-.022l.007,0a.362.362,0,0,0,.065-.04.4.4,0,0,0,.063-.042l.007,0c.013-.014.018-.031.03-.046a.4.4,0,0,0,.048-.072.425.425,0,0,0,.018-.07.243.243,0,0,0,0-.158A.436.436,0,0,0,151.773,176.283Zm-5.311-10.539a1.143,1.143,0,1,1,1.143,1.143A1.144,1.144,0,0,1,146.462,165.745Zm-1.738,9.2,1.233-3.451,1.109,1.109Zm1.516-4.245,1.1-3.076a1.331,1.331,0,0,0,.53,0l1.1,3.076-1.365,1.365Zm1.9,1.9,1.109-1.109,1.233,3.451Z"
              transform="translate(-139.604 -157.745)"
            />
            <path
              id="Path_15752"
              data-name="Path 15752"
              d="M131.065,90.118a.38.38,0,0,0,.537-.039,4.891,4.891,0,0,0,1.182-3.205,4.952,4.952,0,0,0-9.9,0,4.891,4.891,0,0,0,1.182,3.205.381.381,0,1,0,.576-.5,4.131,4.131,0,0,1-1-2.707,4.19,4.19,0,0,1,8.381,0,4.131,4.131,0,0,1-1,2.707A.38.38,0,0,0,131.065,90.118Z"
              transform="translate(-119.832 -78.873)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BroadcastRadio;
