import {
  Avatar,
  Box,
  Typography,
  Divider,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import EmptyStar from "../../../SVGs/EmptyStar";
import HalfStar from "../../../SVGs/HalfStar";
import FullStar from "../../../SVGs/FullStar";
import SendBtn from "../../../SVGs/SendBtn";
import InsertEmoji from "../../../SVGs/InsertEmoji";

function CustomComments({ image, name, comment, star, likes }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "1em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          gap: "0.5em",
        }}
      >
        <Avatar src={image} sx={{ cursor: "pointer" }}></Avatar>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="body1"
            sx={{
              color: "#323232",
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#6C6C6C",
            }}
          >
            {comment}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              transition: "0.5s all",
              fill: "#c8c8c8",
              stroke: "#c8c8c8",
              "&:hover": {
                fill: "#25AAE1",
                cursor: "pointer",
                stroke: "#25AAE1",
              },
            }}
          >
            {star}
          </Box>
          <Typography
            variant="caption"
            sx={{ color: "#7A7A7A", pointerEvents: "none" }}
          >
            {likes}
          </Typography>
        </Box>

        <Button
          size="small"
          sx={{
            color: "#323232",
            textDecoration: "underline",
            textTransform: "none",
            transition: "0.5s all",
            "&:hover": {
              textDecoration: "underline",
              color: "#25AAE1",
            },
          }}
        >
          Reply
        </Button>
      </Box>
    </Box>
  );
}

function Comments() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1em",
        padding: "2em",
        backgroundColor: "#FAFAFA",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#323232",
            }}
          >
            Comments
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: "#6C6C6C",
            }}
          >
            23k Comment
          </Typography>
        </Box>

        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5em",
          }}
        >
          <img src="/images/swap.png" alt="" style={{ width: "15px" }} />
          <Typography
            variant="caption"
            sx={{
              transition: "0.5s all",
              color: "#323232",
              "&: hover": {
                color: "#25AAE1",
              },
            }}
          >
            Top Comment
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F3F3F3",
          borderRadius: "10px",
        }}
      >
        <CustomComments
          image={"/images/soha.png"}
          name={"Soha Alaa"}
          comment={
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the1500s, when an unknown printer took a galley of type and"
          }
          star={<EmptyStar />}
          likes={"0"}
        />

        <Divider
          variant="middle"
          sx={{
            height: "1px",
            backgroundColor: "white",
            borderColor: "white",
          }}
        />

        <CustomComments
          image={"/images/mona.png"}
          name={"Mona Tawfic"}
          comment={"Lorem Ipsum is simply dummy text of the printing"}
          star={<HalfStar />}
          likes={"438"}
        />

        <Divider
          variant="middle"
          sx={{
            height: "1px",
            backgroundColor: "white",
            borderColor: "white",
          }}
        />

        <CustomComments
          image={"/images/nanci.png"}
          name={"Nanci Aman"}
          comment={
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the1500s, when an unknown printer took a galley of type and the remaining essentially unchanged.imply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the1500s, when an unknown printer took a galley of type and the remaining essentially unchanged."
          }
          star={<FullStar />}
          likes={"6k"}
        />

        <Divider
          variant="middle"
          sx={{
            height: "1px",
            backgroundColor: "white",
            borderColor: "white",
          }}
        />

        <CustomComments
          image={"/images/yara.png"}
          name={"Yara Ashraf"}
          comment={"Lorem Ipsum"}
          star={<HalfStar />}
          likes={"200"}
        />

        <Divider
          variant="middle"
          sx={{
            height: "1px",
            backgroundColor: "white",
            borderColor: "white",
          }}
        />

        <CustomComments
          image={"/images/shourok.png"}
          name={"Shourok Ahmed"}
          comment={
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the1500s, when an unknown printer took a galley of type and the remaining essentially unchanged."
          }
          star={<FullStar />}
          likes={"2k"}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "middle",
            gap: { xs: "0", sm: "1em" },
            padding: "0 2em 2em 2em",
          }}
        >
          <TextField
            placeholder="Write your comment..."
            sx={{
              width: { xs: "100%", sm: "50%" },
              borderRadius: "5px",
              backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #E9E9E9",
                },
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  border: "1px solid #25AAE1",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: "2px solid #25AAE1",
                },
            }}
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton
                  size="small"
                  sx={{
                    fill: "#7a7a7a",
                    transition: "0.5s all",
                    "&:hover": {
                      backgroundColor: "transparent",
                      fill: "#25AAE1",
                    },
                  }}
                >
                  <InsertEmoji />
                </IconButton>
              ),
            }}
          />
          <Button
            size="small"
            sx={{
              backgroundColor: "#25AAE1",
              width: "40px",
              height: "40px",
              minHeight: "fit-content",
              minWidth: "fit-content",
              transition: "0.5s all",
              stroke: "#fff",
              "&:hover": {
                outline: "1px solid #25AAE1",
                backgroundColor: "rgba(255, 255, 255, 0.6)",
                stroke: "#25AAE1",
              },
            }}
          >
            <SendBtn />
          </Button>
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          sx={{
            fontSize: "14px",
            color: "#323232",
            transition: "0.5s all",
            "&:hover": {
              color: "white",
              backgroundColor: "#25AAE1",
            },
          }}
        >
          See More
        </Button>
      </Box>
    </Box>
  );
}

export default Comments;
