import { Box, Button, Typography } from "@mui/material";
import PlayBtn from "../../../SVGs/PlayBtn";
import Competition from "../../../SVGs/Competition";

function CustomVideos({
  video,
  title,
  description,
  date,
  views,
  engagement,
  name,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        outline: "1px solid #eeeeee",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <video
          src=""
          poster={video}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            borderRadius: "0px",
            objectFit: "cover",
          }}
        />
        <Box
          style={{
            backgroundColor: "rgba(50, 50, 50, 0.38)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 5,
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            fill: "#f9f9f9",
            stroke: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <PlayBtn />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          padding: "1em",
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#323232",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#6C6C6C",
          }}
        >
          {description}
        </Typography>
        <Box
          sx={{
            color: "#aeaeae",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "0.5em",
            width: "100%",
          }}
        >
          <Typography variant="caption">{date}</Typography>
          <Typography variant="caption">{views}</Typography>
          <Typography variant="caption">{engagement}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "end",
            gap: "0.5em",
          }}
        >
          <Box
            sx={{
              stroke: "#7a7a7a",
              width: {
                xs: "18px",
                md: "20px",
                xl: "30px",
              },
            }}
          >
            <Competition />
          </Box>

          <Typography
            variant="body2"
            sx={{
              color: "#7A7A7A",
            }}
          >
            {name}
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="large"
          sx={{
            textTransform: "none",
            backgroundColor: "#EA5069",
            boxShadow: "0px 7px 15px #EA506940",
            width: { sm: "100%", md: "70%" },
            transition: "0.5s all",
            "&:hover": {
              backgroundColor: "white",
              color: "#EA5069",
              outline: "1px solid #EA5069",
            },
            fontFamily: "RobotoMedium",
            fontSize: "1rem",
          }}
        >
          Watch Now
        </Button>
      </Box>
    </Box>
  );
}

function RelatedVideos() {
  return (
    <Box
      sx={{
        padding: "2em",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#323232",
        }}
      >
        Related Videos
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          justifyContent: "center",
          alignItems: "center",
          gap: "2em",
        }}
      >
        <CustomVideos
          video={"/images/firstVideo.png"}
          title={"As Russia’s military stalls"}
          description={
            "For Prigozhin, the chief bureaucrat that he has in mind is Defense Minister Sergei Shoigu. The two appear to have fallen out over lucrative military contracts given to and then taken from Prigozhin’s"
          }
          date={"3 days ago"}
          views={"7k Views"}
          engagement={"4K Engagement"}
          name={"Ahmed Amir"}
        />

        <CustomVideos
          video={"/images/secondVideo.png"}
          title={"As Russia’s military stalls"}
          description={
            "For Prigozhin, the chief bureaucrat that he has in mind is Defense Minister Sergei Shoigu. The two appear to have fallen out over lucrative military contracts given to and then taken from Prigozhin’s"
          }
          date={"3 days ago"}
          views={"7k Views"}
          engagement={"4K Engagement"}
          name={"Ahmed Amir"}
        />

        <CustomVideos
          video={"/images/thirdVideo.png"}
          title={"As Russia’s military stalls"}
          description={
            "For Prigozhin, the chief bureaucrat that he has in mind is Defense Minister Sergei Shoigu. The two appear to have fallen out over lucrative military contracts given to and then taken from Prigozhin’s"
          }
          date={"3 days ago"}
          views={"7k Views"}
          engagement={"4K Engagement"}
          name={"Ahmed Amir"}
        />

        <CustomVideos
          video={"/images/fourthVideo.png"}
          title={"As Russia’s military stalls"}
          description={
            "For Prigozhin, the chief bureaucrat that he has in mind is Defense Minister Sergei Shoigu. The two appear to have fallen out over lucrative military contracts given to and then taken from Prigozhin’s"
          }
          date={"3 days ago"}
          views={"7k Views"}
          engagement={"4K Engagement"}
          name={"Ahmed Amir"}
        />
      </Box>
    </Box>
  );
}

export default RelatedVideos;
