import { getRequest, postRequest } from "../../../connection/networkPyxiz";
import {
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../types";

const authApis = {
  me: "auth/me",
  login: "auth/login",
};
export const logoutAction = () => (dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
};

export const getUser = async (dispatch) => {
  try {
    const token = localStorage.getItem("token");

    if (!token) return dispatch({ type: AUTH_ERROR });

    let deviceToken = localStorage?.getItem("firebaseToken");

    const res = await getRequest({
      token,
      endPoint: authApis.me,
      query: { deviceToken },
    });

    const { user } = res.data.data;

    dispatch({
      type: USER_LOADED,
      payload: {
        token,
        user,
      },
    });

    return { token };
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
    return { token: null };
  }
};

export const login =
  ({ mobile, password, pageId, companyId, cb }) =>
  (dispatch) => {
    let deviceToken = localStorage?.getItem("firebaseToken");
    return postRequest({
      body: {
        mobile,
        password,
        deviceToken,
        pageId: pageId || undefined,
        companyId: companyId || undefined,
      },
      endPoint: authApis.login,
    })
      .catch((error) => {
        if (error?.message?.includes("405") && cb) cb(true);
        dispatch({
          type: AUTH_ERROR,
          payload: error?.message?.includes("405")
            ? "Email not verified"
            : "Invalid mobile, email or password",
        });
      })
      .then((res) => {
        localStorage.setItem("token", res.data.data.token);
        const {
          data: { user, token },
        } = res.data;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            token,
            user,
          },
        });
      });
  };
