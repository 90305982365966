import { addPollOptionImageService, answerPollService, createNewPollService, deleteMyPollChoiceService, getPostsService } from "../../../services/poll";
import {
  CHOOSE_POLL_OPTION,
  DELETE_CHOICE_POLL_OPTION,
  CHOOSE_MESSAGE_POLL_OPTION,
  UPDATE_POLL_OPTION,
  LOAD_POSTS,
  LOAD_POSTS_ERROR,
  LOAD_POSTS_LOADING,
} from "../types";

export const answerPollAction =
  ({ pollId, optionId, questionId, postId }) =>
  (dispatch) => {
    return answerPollService({ pollId, optionId, questionId, postId }).then(
      (res) => {
        if (res.error) return console.error(res.error);
        dispatch({
          type: CHOOSE_POLL_OPTION,
          payload: { poll: res.data, postId },
        });
      }
    );
  };

export const deleteChoiceAction =
  ({ pollId, questionId, postId }) =>
  (dispatch) => {
    return deleteMyPollChoiceService({ pollId, questionId }).then((res) => {
      if (res.error) return console.error(res.error);
      dispatch({
        type: DELETE_CHOICE_POLL_OPTION,
        payload: { ...res.data, postId, questionId },
      });
    });
  };

export const answerPollMessageAction =
  ({ pollId, optionId, questionId, messageId }) =>
  (dispatch) => {
    return answerPollService({ pollId, optionId, questionId }).then((res) => {
      if (res.error) return console.error(res.error);
      dispatch({
        type: CHOOSE_MESSAGE_POLL_OPTION,
        payload: { poll: res.data, messageId },
      });
    });
  };

export const addPollOptionImageAction =
  ({ questionId, postId, data, cb }) =>
  (dispatch) => {
    return addPollOptionImageService({ data, cb }).then((res) => {
      if (res.error) return cb && cb(res.error);
      dispatch({
        type: UPDATE_POLL_OPTION,
        payload: { option: res.data, postId, questionId },
      });
      cb && cb();
    });
  };

export const createPollAction =
  ({ data, cb = () => {} }) =>
  (dispatch) => {
    createNewPollService({ data }).then((res) => {
      const { data, error } = res;
      if (error) {
        console.error(error);
        return cb(error);
      }
      if (cb) cb(null, data);
    });
  };

export const getPostsAction =
  ({
    page,
    limit,
    pageId,
    next,
    categoryId,
    type,
    text,
    sortBy,
    mediaType,
    selectedDay,
    postTypeId,
    pollTypeId,
    fileTypeId,
    eventTypeId,
    contactPosts,
    home,
    myId,
    pollCategoryId,
    eventCategoryId,
    startDate,
    dateWithIn,
    id,
  }) =>
  (dispatch) => {
    if (!text) text = undefined;
    if (!categoryId) categoryId = undefined;
    if (next) {
      let { thisPage, hasMore, loading } = store?.getState()?.posts;
      if (loading) return;
      if (!hasMore) return;
      page = thisPage + 1;
    }

    dispatch({ type: LOAD_POSTS_LOADING });
    console.log(id);
    return getPostsService({
      page,
      limit,
      pageId,
      categoryId,
      type,
      text,
      sortBy,
      mediaType,
      selectedDay,
      postTypeId,
      pollTypeId,
      fileTypeId,
      eventTypeId,
      contactPosts,
      home,
      myId,
      pollCategoryId,
      eventCategoryId,
      startDate,
      dateWithIn,
      id,
    })
      .catch((e) =>
        dispatch({
          type: LOAD_POSTS_ERROR,
          payload: e,
        })
      )
      .then((d) => {
        if (d.error) {
          return dispatch({
            type: LOAD_POSTS_ERROR,
            payload: d.error,
          });
        }

        let byId = {};
        let allIds = [];

        if (sortBy === "date") {
          Object.values(d.data).map((post) => {
            return (byId[post?.id] = post);
          });
          allIds = d?.data;
        } else {
          d.data.map((post) => {
            byId[post?.id] = post;
            return allIds.push(post.id);
          });
        }

        delete d.data;
        d.byId = byId;
        d.allIds = allIds;

        if (sortBy) {
          d.sortBy = sortBy;
        }

        dispatch({
          type: LOAD_POSTS,
          payload: d,
        });
      });
  };
