import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar,
} from "@mui/material";
import TVServices from "../SVGs/TVServices";
import MoviesIcon from "../SVGs/Movies";
import Playlists from "../SVGs/Playlists";
import NewArrivals from "../SVGs/NewArrivals";
import AlarmIcon from "../SVGs/Alarm";
import TVServicesComponent from "../pages/TV Services/index";
import PyxizTVComponent from "../pages/PyxizTV/index";
import Header from "./Header";
import { useDispatch } from "react-redux";
import { getUser, login } from "../store/actions/auth";

const drawerWidth = 150;

function ResponsiveDrawer() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleVideoClick = (video) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setSelectedItem(video);
  };

  useEffect(() => {
    getUser(dispatch);
  }, [dispatch]);

  const fetchLogin = async ({ countryCode, mobile, password }) => {
    await login({
      mobile: countryCode + mobile,
      password,
    })(dispatch);
  };

  useEffect(() => {
    return fetchLogin({
      countryCode: "20",
      mobile: "1099093619",
      password: "123456789",
    });
  }, []);
  const [selectedItem, setSelectedItem] = useState(0);
  let DrawerListItems = [
    {
      icon: <TVServices />,
      text: "TV Services",
    },
    {
      icon: <MoviesIcon />,
      text: "Movies",
    },
    {
      icon: <Playlists />,
      text: "Playlists",
    },
    {
      icon: <NewArrivals />,
      text: "New Arrivals",
    },
    {
      icon: <AlarmIcon />,
      text: "Coming Soon",
    },
  ];

  const components = [
    <TVServicesComponent onVideoClick={handleVideoClick} />,
    <PyxizTVComponent />,
  ];

  const drawer = (
    <Box>
      <Toolbar
        style={{
          backgroundImage: `url(/images/pyxizLogo.png)`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          cursor: "pointer",
        }}
        onClick={() => setSelectedItem(null)}
      />
      <Divider />
      <List>
        {DrawerListItems?.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem key={index} disablePadding>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "0.3s all",
                  fill: selectedItem === index ? "#25aae1" : "#a9a9a9",
                  stroke: selectedItem === index ? "#25aae1" : "#a9a9a9",
                  color: selectedItem === index ? "#25aae1" : "#a9a9a9",
                  "&:hover": {
                    fill: "#25aae1",
                    stroke: "#25aae1",
                    color: "#25aae1",
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => setSelectedItem(index)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      width: { xs: "30px", lg: "40px" },
                      height: { xs: "30px", lg: "40px" },
                    }}
                  >
                    {item?.icon}
                  </ListItemIcon>
                  <ListItemText primary={item?.text} />
                </Box>
              </ListItemButton>
            </ListItem>
            {index < DrawerListItems.length && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Header handleDrawerToggle={handleDrawerToggle} />
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          width: "100%",
        }}
      >
        <Toolbar />
        {selectedItem !== null && components[selectedItem]}
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
