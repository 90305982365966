function CreateChannel() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <defs>
        <clipPath id="clip-path">
          <path
            id="path4779"
            d="M0-682.665H16v16H0Z"
            transform="translate(0 682.665)"
          />
        </clipPath>
      </defs>
      <g id="g4773" transform="translate(0 682.665)">
        <g id="g4775" transform="translate(0 -682.665)">
          <g id="g4777" clipPath="url(#clip-path)">
            <g id="g4783" transform="translate(1.068 8.788)">
              <path
                id="path4785"
                d="M0,0A6.979,6.979,0,0,0,6.145,6.145"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4787" transform="translate(8.788 8.788)">
              <path
                id="path4789"
                d="M0-256.028a6.979,6.979,0,0,0,6.145-6.145"
                transform="translate(0 262.173)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4791" transform="translate(1.068 1.068)">
              <path
                id="path4793"
                d="M0-256.028a6.979,6.979,0,0,1,6.145-6.145"
                transform="translate(0 262.173)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4795" transform="translate(8.788 1.068)">
              <path
                id="path4797"
                d="M0,0A6.979,6.979,0,0,1,6.145,6.145"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4799" transform="translate(4.235 4.875)">
              <path
                id="path4801"
                d="M0,0A6.46,6.46,0,0,0,3.765,1.168,6.46,6.46,0,0,0,7.53,0"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4803" transform="translate(4.235 9.957)">
              <path
                id="path4805"
                d="M-313.767-48.647a6.46,6.46,0,0,0-3.765-1.168,6.46,6.46,0,0,0-3.765,1.168"
                transform="translate(321.298 49.815)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4807" transform="translate(5.475 3.107)">
              <path
                id="path4809"
                d="M-210.45-203.873c0,2.7-1.131,4.893-2.525,4.893s-2.525-2.191-2.525-4.893,1.131-4.893,2.525-4.893S-210.45-206.575-210.45-203.873Z"
                transform="translate(215.501 208.766)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4811" transform="translate(3.85 3.107)">
              <path
                id="path4813"
                d="M0-95.8a4.89,4.89,0,0,1,4.15-2.3A4.893,4.893,0,0,1,9.043-93.2a4.926,4.926,0,0,1-.073.844"
                transform="translate(0 98.097)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4815" transform="translate(3.107 6.394)">
              <path
                id="path4817"
                d="M-391.757-146.209a4.894,4.894,0,0,1-4.509,2.99,4.893,4.893,0,0,1-4.893-4.893,4.886,4.886,0,0,1,.27-1.606"
                transform="translate(401.159 149.718)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4819" transform="translate(3.107 8)">
              <path
                id="path4821"
                d="M0,0H9.786"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4823" transform="translate(8 3.107)">
              <path
                id="path4825"
                d="M0,0V9.786"
                transform="translate(0 0)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4827" transform="translate(7.211 0.234)">
              <path
                id="path4829"
                d="M-65.765-32.883a.789.789,0,0,1-.789.789.789.789,0,0,1-.789-.789.789.789,0,0,1,.789-.789A.789.789,0,0,1-65.765-32.883Z"
                transform="translate(67.344 33.672)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4831" transform="translate(0.234 0.234)">
              <path
                id="path4833"
                d="M-65.765-32.883a.789.789,0,0,1-.789.789.789.789,0,0,1-.789-.789.789.789,0,0,1,.789-.789A.789.789,0,0,1-65.765-32.883Z"
                transform="translate(67.344 33.672)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4835" transform="translate(14.187 7.211)">
              <path
                id="path4837"
                d="M-65.765-32.883a.789.789,0,0,1-.789.789.789.789,0,0,1-.789-.789.789.789,0,0,1,.789-.789A.789.789,0,0,1-65.765-32.883Z"
                transform="translate(67.344 33.672)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4839" transform="translate(7.211 14.187)">
              <path
                id="path4841"
                d="M-65.765-32.883a.789.789,0,0,1-.789.789.789.789,0,0,1-.789-.789.789.789,0,0,1,.789-.789A.789.789,0,0,1-65.765-32.883Z"
                transform="translate(67.344 33.672)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4843" transform="translate(0.234 7.211)">
              <path
                id="path4845"
                d="M-65.765-32.883a.789.789,0,0,1-.789.789.789.789,0,0,1-.789-.789.789.789,0,0,1,.789-.789A.789.789,0,0,1-65.765-32.883Z"
                transform="translate(67.344 33.672)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4847" transform="translate(1.592 1.592)">
              <path
                id="path4849"
                d="M-60.5-60.505l-1.452-1.452"
                transform="translate(61.956 61.957)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4851" transform="translate(14.187 0.234)">
              <path
                id="path4853"
                d="M-32.883-65.765a.789.789,0,0,0,.789-.789.789.789,0,0,0-.789-.789.789.789,0,0,0-.789.789A.789.789,0,0,0-32.883-65.765Z"
                transform="translate(33.672 67.344)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4855" transform="translate(12.956 1.592)">
              <path
                id="path4857"
                d="M0-60.505l1.452-1.452"
                transform="translate(0 61.957)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4859" transform="translate(0.234 14.187)">
              <path
                id="path4861"
                d="M-32.883-65.765a.789.789,0,0,0,.789-.789.789.789,0,0,0-.789-.789.789.789,0,0,0-.789.789A.789.789,0,0,0-32.883-65.765Z"
                transform="translate(33.672 67.344)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4863" transform="translate(1.592 12.956)">
              <path
                id="path4865"
                d="M-60.5,0l-1.452,1.452"
                transform="translate(61.956)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4867" transform="translate(14.187 14.187)">
              <path
                id="path4869"
                d="M-65.765-32.883a.789.789,0,0,1-.789.789.789.789,0,0,1-.789-.789.789.789,0,0,1,.789-.789A.789.789,0,0,1-65.765-32.883Z"
                transform="translate(67.344 33.672)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
            <g id="g4871" transform="translate(12.956 12.956)">
              <path
                id="path4873"
                d="M0,0,1.452,1.452"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.6"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CreateChannel;
