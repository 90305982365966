import axios from "axios";
const baseURL =  "https://spinner-back.shutterstudio.io";

const postRequest = async ({ body, query, params, endPoint, token }) => {
  return await axios.post(
    `${baseURL}/${endPoint}${params ? "/" + params : ""}`,
    body,
    {
      params: query,
      headers: {
        ...(token ? { "access-token": token } : {}),
        "Content-Type": "application/json",
      },
    }
  );
};

const getRequest = async ({ query, params, endPoint, token }) => {
  return await axios.get(
    `${baseURL}/${endPoint}${params ? "/" + params : ""}`,
    {
      params: query,
      headers: {
        ...(token ? { Authorization: token ? token : undefined } : {}),
        "Content-Type": "application/json",
        "accept-language": localStorage?.getItem("lng") || "en",
      },
    }
  );
};

const delRequest = async ({ query, params, endPoint, token }) => {
  return await axios.delete(
    `${baseURL}${endPoint}${params ? "/" + params : ""}`,
    {
      params: query,
      headers: {
        ...(token ? { Authorization: token ? token : undefined } : {}),
        "Content-Type": "application/json",
      },
    }
  );
};

const putRequest = async ({ body, query, params, endPoint, token }) => {
  return await axios.put(
    `${baseURL}/${endPoint}${params ? "/" + params : ""}`,
    body,
    {
      params: query,
      headers: {
        ...(token ? { Authorization: token ? token : undefined } : {}),
        "Content-Type": "application/json",
      },
    }
  );
};

const patchRequest = async (body, { query, params, endPoint, token }) => {
  return await axios.patch(
    `${baseURL}/${endPoint}${params ? "/" + params : ""}`,
    body,
    {
      params: query,
      headers: {
        ...(token ? { Authorization: token ? token : undefined } : {}),
        "Content-Type": "application/json",
      },
    }
  );
};

export {
  postRequest,
  getRequest,
  delRequest,
  patchRequest,
  putRequest,
  baseURL,
};
