import { LOG_IN, LOG_OUT, SET_SESSION } from "../types";
import store from "./../../index";

export const dispatch = (type, payload) => {
  store.dispatch({ type, payload });
};

export const login = (payload) => {
  dispatch(LOG_IN, payload);
};

export const logout = (payload) => {
  dispatch(LOG_OUT, payload);
};

export const setSession = (payload) => {
  dispatch(SET_SESSION, payload);
};
