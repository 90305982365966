export default function TVServices() {
  return (
    <svg
      id="tv"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43.8 43.8"
      stroke="none"
    >
      <g id="Group_65372" data-name="Group 65372" transform="translate(0 0)">
        <g id="Group_65371" data-name="Group 65371">
          <path
            id="Path_15794"
            data-name="Path 15794"
            d="M42.089,7.956H23.965l6.5-6.5A.856.856,0,1,0,29.251.25L21.9,7.6,14.549.25a.856.856,0,0,0-1.21,1.21l6.5,6.5H1.711A1.713,1.713,0,0,0,0,9.667V38.533a1.713,1.713,0,0,0,1.711,1.711h1.7l1.6,3.093a.856.856,0,0,0,.76.463h3.9a.856.856,0,0,0,.76-.463l1.6-3.093H31.773l1.6,3.093a.856.856,0,0,0,.76.463h3.9a.856.856,0,0,0,.76-.463l1.6-3.093h1.7A1.713,1.713,0,0,0,43.8,38.533V9.667A1.713,1.713,0,0,0,42.089,7.956ZM9.148,42.089H6.289l-.953-1.845H10.1Zm28.362,0H34.651L33.7,40.244h4.765Zm4.578-3.555H1.711V9.667H42.089V38.533Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
      <g
        id="Group_65374"
        data-name="Group 65374"
        transform="translate(3.479 11.541)"
      >
        <g id="Group_65373" data-name="Group 65373">
          <path
            id="Path_15795"
            data-name="Path 15795"
            d="M67.762,134.91H45.119a4.457,4.457,0,0,0-4.452,4.452v15.894a4.457,4.457,0,0,0,4.452,4.452H67.762a4.457,4.457,0,0,0,4.452-4.452V139.362A4.457,4.457,0,0,0,67.762,134.91ZM70.5,155.255A2.744,2.744,0,0,1,67.762,158H45.119a2.744,2.744,0,0,1-2.741-2.741V139.362a2.744,2.744,0,0,1,2.741-2.741h13.8L45.956,149.588a.855.855,0,1,0,1.21,1.21l14.177-14.177h6.419a2.744,2.744,0,0,1,2.741,2.741Z"
            transform="translate(-40.667 -134.91)"
          />
        </g>
      </g>
      <g
        id="Group_65376"
        data-name="Group 65376"
        transform="translate(36.052 23.506)"
      >
        <g id="Group_65375" data-name="Group 65375">
          <path
            id="Path_15796"
            data-name="Path 15796"
            d="M424,274.778a2.566,2.566,0,1,0,2.566,2.566A2.569,2.569,0,0,0,424,274.778Zm0,3.422a.855.855,0,1,1,.855-.855A.856.856,0,0,1,424,278.2Z"
            transform="translate(-421.431 -274.778)"
          />
        </g>
      </g>
      <g
        id="Group_65378"
        data-name="Group 65378"
        transform="translate(36.052 31.205)"
      >
        <g id="Group_65377" data-name="Group 65377">
          <path
            id="Path_15797"
            data-name="Path 15797"
            d="M424,364.778a2.566,2.566,0,1,0,2.566,2.566A2.569,2.569,0,0,0,424,364.778Zm0,3.422a.855.855,0,1,1,.855-.855A.856.856,0,0,1,424,368.2Z"
            transform="translate(-421.431 -364.778)"
          />
        </g>
      </g>
      <g
        id="Group_65380"
        data-name="Group 65380"
        transform="translate(36.052 16.018)"
      >
        <g id="Group_65379" data-name="Group 65379">
          <path
            id="Path_15798"
            data-name="Path 15798"
            d="M425.5,187.249h-3.214a.855.855,0,0,0,0,1.711H425.5a.855.855,0,0,0,0-1.711Z"
            transform="translate(-421.431 -187.249)"
          />
        </g>
      </g>
      <g
        id="Group_65382"
        data-name="Group 65382"
        transform="translate(36.052 12.54)"
      >
        <g id="Group_65381" data-name="Group 65381">
          <path
            id="Path_15799"
            data-name="Path 15799"
            d="M425.5,146.582h-3.214a.855.855,0,0,0,0,1.711H425.5a.855.855,0,0,0,0-1.711Z"
            transform="translate(-421.431 -146.582)"
          />
        </g>
      </g>
      <g
        id="Group_65384"
        data-name="Group 65384"
        transform="translate(36.052 19.497)"
      >
        <g id="Group_65383" data-name="Group 65383">
          <path
            id="Path_15800"
            data-name="Path 15800"
            d="M425.5,227.916h-3.214a.855.855,0,0,0,0,1.711H425.5a.855.855,0,0,0,0-1.711Z"
            transform="translate(-421.431 -227.916)"
          />
        </g>
      </g>
      <g
        id="Group_65386"
        data-name="Group 65386"
        transform="translate(14.632 19.591)"
      >
        <g id="Group_65385" data-name="Group 65385">
          <path
            id="Path_15801"
            data-name="Path 15801"
            d="M180.327,229.257a.856.856,0,0,0-1.21,0l-7.824,7.824a.855.855,0,1,0,1.21,1.21l7.824-7.824A.856.856,0,0,0,180.327,229.257Z"
            transform="translate(-171.043 -229.006)"
          />
        </g>
      </g>
      <g
        id="Group_65388"
        data-name="Group 65388"
        transform="translate(11.677 30.369)"
      >
        <g id="Group_65387" data-name="Group 65387">
          <path
            id="Path_15802"
            data-name="Path 15802"
            d="M137.96,355.251a.855.855,0,1,0,.251.6A.861.861,0,0,0,137.96,355.251Z"
            transform="translate(-136.5 -355)"
          />
        </g>
      </g>
    </svg>
  );
}
