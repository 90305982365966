function InsertEmoji() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path
        id="Icon_material-insert-emoticon"
        data-name="Icon material-insert-emoticon"
        d="M14.988,2.5A12.5,12.5,0,1,0,27.5,15,12.494,12.494,0,0,0,14.988,2.5ZM15,25A10,10,0,1,1,25,15,10,10,0,0,1,15,25Zm4.375-11.25A1.875,1.875,0,1,0,17.5,11.875,1.872,1.872,0,0,0,19.375,13.75Zm-8.75,0A1.875,1.875,0,1,0,8.75,11.875,1.872,1.872,0,0,0,10.625,13.75ZM15,21.875A6.87,6.87,0,0,0,21.388,17.5H8.613A6.87,6.87,0,0,0,15,21.875Z"
        transform="translate(-2.5 -2.5)"
      />
    </svg>
  );
}

export default InsertEmoji;
