export default function Playlists() {
  return (
    <svg
      id="Ikon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40.2 34.729"
      stroke="none"
    >
      <path
        id="Path_15803"
        data-name="Path 15803"
        d="M17.61,11.706,6.678,5.692A3.158,3.158,0,0,0,2,8.459V20.484A3.158,3.158,0,0,0,6.679,23.25L17.61,17.238a3.157,3.157,0,0,0,0-5.532Zm-1.522,2.765h0L5.157,20.484V8.459L16.1,14.466ZM44.621,8.157h-22.1a1.579,1.579,0,1,1,0-3.157h22.1a1.579,1.579,0,0,1,0,3.157Zm0,7.893h-22.1a1.579,1.579,0,0,1,0-3.157h22.1a1.579,1.579,0,0,1,0,3.157Zm0,7.893h-22.1a1.579,1.579,0,0,1,0-3.157h22.1a1.579,1.579,0,1,1,0,3.157Zm0,7.893H3.579a1.579,1.579,0,0,1,0-3.157H44.621a1.579,1.579,0,0,1,0,3.157Zm0,7.893H3.579a1.579,1.579,0,0,1,0-3.157H44.621a1.579,1.579,0,0,1,0,3.157Z"
        transform="translate(-2 -5)"
      />
    </svg>
  );
}
