import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PollAnswer from "./PollButton";
import { PollsService } from "../../../services/PollsService";

const PollQuestion = ({
  question,
  setCurrentQuestion,
  showCountdown = true,
  livePoll,
}) => {
  const [currentTime, setCurrentTime] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [maxWinnerId, setMaxWinnerId] = useState();
  const [votes, setVotes] = useState();

  // let { pollId } = useParams();
  let pollId = 1;
  let isTimeUp = currentTime <= 0;

  useEffect(() => {
    openScreenSocket();

    return () => {
      PollsService.leaveRoom(pollId);
    };
  }, []);

  useEffect(() => {
    resetData();
  }, [question]);

  useEffect(() => {
    updateWinner();

    if (isTimeUp) {
      PollsService.stopQuestionVoting(pollId, question?.id);
    }
  }, [votes, isTimeUp]);

  const openScreenSocket = () => {
    PollsService.joinRoom(pollId, updateCurrentQuestion, updateVotes);
  };

  const updateCurrentQuestion = (data) => {
    setVotes(data?.votes);
    setCurrentQuestion(data?.currentQuestion?.id);
  };

  const updateVotes = (data) => {
    setVotes(data);
  };

  const getNextQuestionId = (answerId) => {
    return question?.answers?.find((e) => e?.id == answerId)?.toQuestionId;
  };

  const updateWinner = () => {
    let maxVote;

    if (votes) {
      for (const vote of votes) {
        if (maxVote == null || vote?.votes > maxVote?.votes) {
          maxVote = vote;
        }
      }
    }

    let winnerId;

    if (maxVote) {
      winnerId = maxVote?.answerId;
    } else if (isTimeUp) {
      winnerId = question?.answers?.find(() => true)?.id;
    }

    setMaxWinnerId(winnerId);

    return winnerId;
  };

  const submitAnswer = (answer) => {
    PollsService.selectAction(pollId, question?.id, answer?.id);
  };

  const resetData = () => {
    setCurrentTime();
    setSelectedAnswer();
    setVotes();
    setMaxWinnerId();
  };

  return (
    <Box className="w-100">
      {livePoll && (
        <Box
          className="d-flex flex-wrap justify-content-center align-items-center"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {question?.answers?.map((answer, i) => (
            <PollAnswer
              key={i}
              title={answer?.title}
              votes={votes?.find((e) => e?.answerId == answer?.id)?.votes ?? 0}
              isWinner={maxWinnerId == answer?.id}
              isTimeUp={isTimeUp}
              isSelected={i == selectedAnswer}
              onClick={() => {
                if (isTimeUp) {
                  setCurrentQuestion(answer.toQuestionId);
                } else {
                  setSelectedAnswer(i);
                  submitAnswer(answer);
                }
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PollQuestion;
