function Emoji1() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.095 32.095">
      <g id="layer1" transform="translate(0)">
        <ellipse
          id="circle1359"
          cx="16.047"
          cy="16.047"
          rx="16.047"
          ry="16.047"
          transform="translate(0 0)"
          fill="#ffd42a"
        />
        <path
          id="path1361"
          d="M23.176,354.161a16.037,16.037,0,1,0,24.847,19.668q-.444.42-.918.806-.628.5-1.3.933t-1.392.8q-.715.364-1.464.655t-1.522.506a16.041,16.041,0,0,1-1.566.351q-.791.137-1.593.193-.566.04-1.134.04c-.533,0-1.068-.027-1.6-.08a16.006,16.006,0,0,1-3.143-.637q-.767-.237-1.507-.55t-1.444-.7q-.7-.385-1.367-.838t-1.277-.971q-.614-.517-1.174-1.093t-1.059-1.206q-.5-.628-.933-1.3t-.8-1.392q-.364-.715-.656-1.464t-.505-1.523q-.215-.773-.351-1.565t-.194-1.593q-.04-.566-.04-1.135,0-.8.08-1.6t.239-1.586a16.044,16.044,0,0,1,.4-1.555q.237-.767.55-1.507t.7-1.444q.064-.105.129-.21Z"
          transform="translate(-18.021 -349.879)"
          fill="#ffbc2a"
        />
        <path
          id="path1472"
          d="M26.772,98.152V77.246H20.958V95.111a16.047,16.047,0,0,0,5.814,3.041Z"
          transform="translate(-15.108 -66.686)"
          fill="#0085a6"
        />
        <path
          id="path1470"
          d="M33.669,95.741v-18.5H27.856V98.361A16.047,16.047,0,0,0,33.669,95.741Z"
          transform="translate(-8.264 -66.686)"
          fill="#0085a6"
        />
        <path
          id="path1455"
          d="M20.669,357.314H27.99"
          transform="translate(-15.394 -346.751)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path1474"
          d="M27.384,357.314h7.321"
          transform="translate(-8.731 -346.751)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path1482"
          d="M29.388,364.532a6.237,6.237,0,0,0-4.565.118"
          transform="translate(-11.273 -339.982)"
          fill="none"
          stroke="#ffbc2a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path1484"
          d="M22.735,363a2.965,2.965,0,0,1,3.095-.033c1.531,1,3.527-1.265,5.058-.732s2.363,1.9,4.592.433"
          transform="translate(-13.345 -341.947)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
      </g>
    </svg>
  );
}

export default Emoji1;
