function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 27.621 27.621"
      fill="none"
    >
      <g
        id="Icon_feather-search"
        data-name="Icon feather-search"
        transform="translate(-3 -3)"
      >
        <path
          id="Path_15745"
          data-name="Path 15745"
          d="M25.833,15.167A10.667,10.667,0,1,1,15.167,4.5,10.667,10.667,0,0,1,25.833,15.167Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_15746"
          data-name="Path 15746"
          d="M30.775,30.775l-5.8-5.8"
          transform="translate(-2.275 -2.275)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}

export default SearchIcon;
