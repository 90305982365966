import { Box, Typography, CircularProgress } from "@mui/material";

export default function CustomCircularProgress({
  value,
  color,
  label,
  size,
  valueColor,
  valueSize,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          variant="determinate"
          value={value}
          size={size}
          sx={{
            color,
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{
              color: valueColor,
              fontSize: valueSize,
              pointerEvents: "none",
            }}
          >
            {`${(value / 10).toFixed(1)}`}
          </Typography>
        </Box>
      </Box>
      {label && <Typography variant="caption">{label}</Typography>}
    </Box>
  );
}
