import { Question } from "./Question";

export const Poll = {
  initialValue: () => initialValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data) => toMap(data),
};

const initialValue = () => ({
  title: "",
  questions: [],
});

const fromJSON = (data) => ({
  id: data?.id,
  title: data?.name,
  questions: data?.questions?.map((e) => Question.fromJSON(e)),
});

const toMap = (data) => ({
  id: data?.id,
  name: data?.title,
  questions: data?.questions?.map((e) => Question.toMap(e)),
});
