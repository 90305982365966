function RequestIcon() {
  return (
    <svg
      id="customer-service"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.8 20.495"
      stroke="none"
    >
      <path
        id="Path_15778"
        data-name="Path 15778"
        d="M300.656,11.454a.347.347,0,0,0-.184-.669c-2.949.809-7.165-4-4.622-6.466a.327.327,0,0,1,.387-.061,2.98,2.98,0,0,1,1.107,1.107.319.319,0,0,1-.039.366,1.5,1.5,0,0,0-.621,1.571,2.708,2.708,0,0,0,1.953,1.953,1.5,1.5,0,0,0,1.571-.62.319.319,0,0,1,.366-.039A2.98,2.98,0,0,1,301.682,9.7a.3.3,0,0,1,.039.154.347.347,0,1,0,.693.013.993.993,0,0,0-.13-.511,3.674,3.674,0,0,0-1.365-1.365,1.014,1.014,0,0,0-1.218.166.859.859,0,0,1-.926.415,2.016,2.016,0,0,1-1.411-1.411.853.853,0,0,1,.412-.923,1.015,1.015,0,0,0,.17-1.221,3.674,3.674,0,0,0-1.365-1.365,1.024,1.024,0,0,0-1.214.165C292.279,6.824,297.041,12.448,300.656,11.454Z"
        transform="translate(-281.616 -3.528)"
      />
      <path
        id="Path_15779"
        data-name="Path 15779"
        d="M7.519,290.445a.347.347,0,0,0-.258.417,4.512,4.512,0,0,1,.019.9.694.694,0,0,1-.693.693h-.52v-2.123h.26a.347.347,0,0,0,.347-.347v-.823a2.687,2.687,0,0,0-5.373,0v.48A1.388,1.388,0,0,0,0,291.022v.737a1.388,1.388,0,0,0,1.387,1.387h.867A.347.347,0,0,0,2.6,292.8v-1.25a.347.347,0,1,0-.693,0v.9h-.52a.694.694,0,0,1-.693-.693v-.737a.694.694,0,0,1,.693-.693h.26a.347.347,0,0,0,.347-.347v-.823a1.993,1.993,0,0,1,3.987,0v.477H5.72a.347.347,0,0,0-.347.347V292.8a.347.347,0,0,0,.347.347h.11a1.45,1.45,0,0,1-1.187.869.347.347,0,1,0,.075.689,2.165,2.165,0,0,0,1.845-1.558,1.388,1.388,0,0,0,1.41-1.387,4.582,4.582,0,0,0-.037-1.057.347.347,0,0,0-.417-.258Z"
        transform="translate(0 -274.211)"
      />
      <path
        id="Path_15780"
        data-name="Path 15780"
        d="M1.387,51.317H9.794a1.388,1.388,0,0,0,1.387-1.387v-.108a2.5,2.5,0,0,0,1.2-.081.867.867,0,0,0,.092-1.606,3.152,3.152,0,0,1-1.339-1.219.347.347,0,0,0-.593.359,3.843,3.843,0,0,0,1.633,1.486.173.173,0,0,1-.011.322,1.946,1.946,0,0,1-1.218-.032.347.347,0,0,0-.455.329v.55a.694.694,0,0,1-.693.693H1.387a.694.694,0,0,1-.693-.693v-5.07a.694.694,0,0,1,.693-.693H9.794a.694.694,0,0,1,.693.693.347.347,0,0,0,.693,0,1.388,1.388,0,0,0-1.387-1.387H1.387A1.388,1.388,0,0,0,0,44.861v5.07A1.388,1.388,0,0,0,1.387,51.317Z"
        transform="translate(0 -41.743)"
      />
      <path
        id="Path_15781"
        data-name="Path 15781"
        d="M65.067,102.819a.347.347,0,0,0-.347-.347H59.347a.347.347,0,1,0,0,.693H64.72A.347.347,0,0,0,65.067,102.819Z"
        transform="translate(-56.443 -98.184)"
      />
      <path
        id="Path_15782"
        data-name="Path 15782"
        d="M59.347,150.472a.347.347,0,1,0,0,.693H63.42a.347.347,0,0,0,0-.693Z"
        transform="translate(-56.443 -144.104)"
      />
      <path
        id="Path_15783"
        data-name="Path 15783"
        d="M192.365,262.472h-7.74a.347.347,0,0,0,0,.693h7.74a.694.694,0,0,1,.693.693V269.8a.694.694,0,0,1-.693.693h-8.407a.694.694,0,0,1-.693-.693v-3.1a.347.347,0,0,0-.05-.18,3.844,3.844,0,0,0-1.633-1.486.173.173,0,0,1,.011-.322,1.946,1.946,0,0,1,1.218.032.347.347,0,0,0,.216-.659,2.63,2.63,0,0,0-1.651-.032.867.867,0,0,0-.092,1.606,3.145,3.145,0,0,1,1.288,1.139v3a1.388,1.388,0,0,0,1.387,1.387h8.407a1.388,1.388,0,0,0,1.387-1.387v-5.937A1.388,1.388,0,0,0,192.365,262.472Z"
        transform="translate(-172.951 -251.251)"
      />
      <path
        id="Path_15784"
        data-name="Path 15784"
        d="M281.347,329.165h5.373a.347.347,0,0,0,0-.693h-5.373a.347.347,0,0,0,0,.693Z"
        transform="translate(-268.823 -314.391)"
      />
      <path
        id="Path_15785"
        data-name="Path 15785"
        d="M281.347,389.165h4.073a.347.347,0,1,0,0-.693h-4.073a.347.347,0,1,0,0,.693Z"
        transform="translate(-268.823 -371.791)"
      />
    </svg>
  );
}

export default RequestIcon;
