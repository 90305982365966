function CustomerService() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 18.64 18.366"
      stroke="none"
    >
      <g id="customer-service" transform="translate(0 -3.528)">
        <path
          id="Path_15778"
          data-name="Path 15778"
          d="M300,10.631a.311.311,0,0,0-.165-.6c-2.642.725-6.42-3.58-4.142-5.795a.293.293,0,0,1,.347-.054,2.67,2.67,0,0,1,.992.992A.286.286,0,0,1,297,5.5a1.34,1.34,0,0,0-.556,1.408,2.427,2.427,0,0,0,1.75,1.75,1.34,1.34,0,0,0,1.408-.556.286.286,0,0,1,.328-.035,2.671,2.671,0,0,1,.992.992.264.264,0,0,1,.035.138.311.311,0,1,0,.621.011.889.889,0,0,0-.117-.458,3.292,3.292,0,0,0-1.223-1.223.909.909,0,0,0-1.091.149.769.769,0,0,1-.83.372,1.807,1.807,0,0,1-1.265-1.265.765.765,0,0,1,.369-.827.909.909,0,0,0,.152-1.094,3.292,3.292,0,0,0-1.223-1.223.917.917,0,0,0-1.088.148C292.5,6.482,296.763,11.521,300,10.631Z"
          transform="translate(-282.941)"
        />
        <path
          id="Path_15779"
          data-name="Path 15779"
          d="M6.738,290.032a.311.311,0,0,0-.231.374,4.042,4.042,0,0,1,.017.8.622.622,0,0,1-.621.621H5.437v-1.9H5.67a.311.311,0,0,0,.311-.311v-.738a2.408,2.408,0,0,0-4.815,0v.43A1.244,1.244,0,0,0,0,290.549v.66a1.244,1.244,0,0,0,1.243,1.243h.777a.311.311,0,0,0,.311-.311v-1.12a.311.311,0,0,0-.621,0v.809H1.243a.622.622,0,0,1-.621-.621v-.66a.622.622,0,0,1,.621-.621h.233a.311.311,0,0,0,.311-.311v-.738a1.786,1.786,0,0,1,3.573,0v.427H5.126a.311.311,0,0,0-.311.311v2.524a.311.311,0,0,0,.311.311h.1a1.3,1.3,0,0,1-1.064.779.311.311,0,1,0,.068.618,1.94,1.94,0,0,0,1.654-1.4,1.244,1.244,0,0,0,1.264-1.243,4.106,4.106,0,0,0-.033-.947.311.311,0,0,0-.374-.231Z"
          transform="translate(0 -271.957)"
        />
        <path
          id="Path_15780"
          data-name="Path 15780"
          d="M1.243,50.5H8.776a1.244,1.244,0,0,0,1.243-1.243v-.1a2.24,2.24,0,0,0,1.072-.072.777.777,0,0,0,.083-1.439,2.824,2.824,0,0,1-1.2-1.093.311.311,0,0,0-.532.322,3.444,3.444,0,0,0,1.463,1.332.155.155,0,0,1-.01.289,1.744,1.744,0,0,1-1.091-.029.311.311,0,0,0-.408.3v.492a.622.622,0,0,1-.621.621H1.243a.622.622,0,0,1-.621-.621V44.717a.622.622,0,0,1,.621-.621H8.776a.622.622,0,0,1,.621.621.311.311,0,1,0,.621,0,1.244,1.244,0,0,0-1.243-1.243H1.243A1.244,1.244,0,0,0,0,44.717V49.26A1.244,1.244,0,0,0,1.243,50.5Z"
          transform="translate(0 -38.395)"
        />
        <path
          id="Path_15781"
          data-name="Path 15781"
          d="M64.437,102.783a.311.311,0,0,0-.311-.311H59.311a.311.311,0,1,0,0,.621h4.815A.311.311,0,0,0,64.437,102.783Z"
          transform="translate(-56.709 -95.102)"
        />
        <path
          id="Path_15782"
          data-name="Path 15782"
          d="M59.311,150.472a.311.311,0,0,0,0,.621h3.65a.311.311,0,0,0,0-.621Z"
          transform="translate(-56.709 -141.238)"
        />
        <path
          id="Path_15783"
          data-name="Path 15783"
          d="M191.162,262.472h-6.936a.311.311,0,0,0,0,.621h6.936a.622.622,0,0,1,.621.621v5.32a.622.622,0,0,1-.621.621h-7.534a.622.622,0,0,1-.621-.621v-2.78a.311.311,0,0,0-.045-.161,3.445,3.445,0,0,0-1.463-1.332.155.155,0,0,1,.01-.289,1.744,1.744,0,0,1,1.091.029.311.311,0,0,0,.194-.59,2.357,2.357,0,0,0-1.48-.028.777.777,0,0,0-.083,1.44,2.818,2.818,0,0,1,1.155,1.021v2.691a1.244,1.244,0,0,0,1.243,1.243h7.534a1.244,1.244,0,0,0,1.243-1.243v-5.32A1.244,1.244,0,0,0,191.162,262.472Z"
          transform="translate(-173.765 -248.889)"
        />
        <path
          id="Path_15784"
          data-name="Path 15784"
          d="M281.311,329.093h4.815a.311.311,0,0,0,0-.621h-4.815a.311.311,0,0,0,0,.621Z"
          transform="translate(-270.088 -312.326)"
        />
        <path
          id="Path_15785"
          data-name="Path 15785"
          d="M281.311,389.093h3.65a.311.311,0,0,0,0-.621h-3.65a.311.311,0,0,0,0,.621Z"
          transform="translate(-270.088 -369.996)"
        />
      </g>
    </svg>
  );
}

export default CustomerService;
