function PlayBtn() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76">
      <g id="play" transform="translate(2 2.388)">
        <g id="Group_65259" data-name="Group 65259" transform="translate(0 0)">
          <circle
            id="Ellipse_1088"
            data-name="Ellipse 1088"
            cx="36"
            cy="36"
            r="36"
            transform="translate(0 -0.388)"
            fill="none"
            strokeWidth="4"
          />
          <g
            id="Group_65258"
            data-name="Group 65258"
            transform="translate(29.294 20.906)"
          >
            <g
              id="Group_65257"
              data-name="Group 65257"
              transform="translate(0 0)"
            >
              <path
                id="Path_15738"
                data-name="Path 15738"
                d="M172.623,142.385A1.624,1.624,0,0,1,171,140.762V112.624a1.623,1.623,0,0,1,2.574-1.316l19.48,14.069a1.623,1.623,0,0,1,0,2.632l-19.48,14.069A1.622,1.622,0,0,1,172.623,142.385Z"
                transform="translate(-171 -111)"
                stroke="none"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PlayBtn;
