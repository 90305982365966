function MenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="10"
      viewBox="0 0 18 12.667"
      fill="none"
    >
      <g
        id="Icon_feather-menu"
        data-name="Icon feather-menu"
        transform="translate(21.5 20.667) rotate(-180)"
      >
        <path
          id="Path_15747"
          data-name="Path 15747"
          d="M4.5,18h16"
          transform="translate(0 -3.667)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_15748"
          data-name="Path 15748"
          d="M4.5,9h9.44"
          transform="translate(6.56)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_15749"
          data-name="Path 15749"
          d="M4.5,27h16"
          transform="translate(0 -7.333)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default MenuIcon;
