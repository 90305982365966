function Quiz() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <defs>
        <clipPath id="clip-path">
          <path
            id="path1980"
            d="M0-682.665H16v16H0Z"
            transform="translate(0 682.665)"
          />
        </clipPath>
      </defs>
      <g id="g1974" transform="translate(0 682.665)">
        <g id="g1976" transform="translate(0 -682.665)">
          <g id="g1978" clipPath="url(#clip-path)">
            <g id="g1984" transform="translate(0.312 0.313)">
              <path
                id="path1986"
                d="M-268.228-320.312h-5.187a1.25,1.25,0,0,1-1.25-1.25v-5.187a1.25,1.25,0,0,1,1.25-1.25h5.188a1.25,1.25,0,0,1,1.25,1.25v5.188A1.25,1.25,0,0,1-268.228-320.312Z"
                transform="translate(274.666 327.999)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
            <g id="g1988" transform="translate(8 0.313)">
              <path
                id="path1990"
                d="M-268.228-320.312h-5.187a1.25,1.25,0,0,1-1.25-1.25v-5.187a1.25,1.25,0,0,1,1.25-1.25h5.188a1.25,1.25,0,0,1,1.25,1.25v5.188A1.25,1.25,0,0,1-268.228-320.312Z"
                transform="translate(274.666 327.999)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
            <g id="g1992" transform="translate(0.312 8)">
              <path
                id="path1994"
                d="M-268.228-320.312h-5.187a1.25,1.25,0,0,1-1.25-1.25v-5.187a1.25,1.25,0,0,1,1.25-1.25h5.188a1.25,1.25,0,0,1,1.25,1.25v5.188A1.25,1.25,0,0,1-268.228-320.312Z"
                transform="translate(274.666 327.999)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
            <g id="g1996" transform="translate(8 8)">
              <path
                id="path1998"
                d="M-268.228-320.312h-5.187a1.25,1.25,0,0,1-1.25-1.25v-5.187a1.25,1.25,0,0,1,1.25-1.25h5.188a1.25,1.25,0,0,1,1.25,1.25v5.188A1.25,1.25,0,0,1-268.228-320.312Z"
                transform="translate(274.666 327.999)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
            <g id="g2000" transform="translate(4.156 10.596)">
              <path
                id="path2002"
                d="M0,0V2.5"
                transform="translate(0)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
            <g id="g2004" transform="translate(11.025 2.904)">
              <path
                id="path2006"
                d="M0,0V1.72a.733.733,0,0,0,.392.648A.842.842,0,0,0,.853,2.5a.9.9,0,0,0,.413-.095.749.749,0,0,0,.471-.687V0"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
            <g id="g2008" transform="translate(4.77 4.594)">
              <path
                id="path2010"
                d="M0,0,.8.8"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
            <g id="g2012" transform="translate(3.06 2.904)">
              <path
                id="path2014"
                d="M-104.166-52.083a1.25,1.25,0,0,1-1.25,1.25,1.25,1.25,0,0,1-1.25-1.25,1.25,1.25,0,0,1,1.25-1.25A1.25,1.25,0,0,1-104.166-52.083Z"
                transform="translate(106.666 53.333)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
            <g id="g2016" transform="translate(11.01 10.596)">
              <path
                id="path2018"
                d="M-2.137,0H-.559L-2.188,2.5H-.52"
                transform="translate(2.188)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.8"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Quiz;
