import { useDispatch, useSelector } from "react-redux";
import { getPostsAction } from "../../../store/actions/poll";
import PostItem from "../Poll";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { MdClose } from "react-icons/md";
import { useState } from "react";

const PollsComponent = () => {
  const posts = useSelector((s) => s.posts.data.byId);
  const allIds = useSelector((s) => s.posts.data.allIds);
  const loading = useSelector((s) => s.posts.loading);
  const user = useSelector((s) => s.auth.user);
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open ? true : false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Box>
          {allIds?.length > 0 ? (
            allIds?.map((postId) => {
              let post = posts[postId];
              return (
                <Box key={postId} width={1}>
                  <PostItem post={post} pageId={141} user={user} />
                </Box>
              );
            })
          ) : (
            <>
              {!loading && (
                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    pt: 1,
                  }}
                >
                  {/* <img src={emptyPolls} alt="" /> */}
                  <Typography
                    sx={{ mt: 3 }}
                    variant="h6"
                    color="text.secondary"
                  >
                    there no
                  </Typography>
                </Box>
              )}
            </>
          )}
          {loading && (
            <Box
              sx={{
                width: 1,
                height: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <CircularProgress height={50} />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default PollsComponent;
