// General constants
export const COMPANY_NAME = "Shutter Stop Events";
export const MAIN_URL = "/";

export const DEFAULT_LOCALE = "ar";

// Network constants
export const BASE_URL = "https://chatbot-api.limberx.com/api";
export const SOCKET_URL = "wss://chatbot-api.limberx.com";
export const REQUEST_TIMEOUT = 10 * 1000; // 10 secs

// Pentavalue contact details
export const COMPANY_FACEBOOK = "https://www.facebook.com/pentavalue/";
export const COMPANY_LINKEDIN = "https://www.linkedin.com/company/pentavalue/";

// Keys
export const LOCAL_STORAGE_STATE_KEY = "PENTAVALUE_POLLS";

// Firebase
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAVSJlEMIksIXGBvddYhPQ9SwzV2JsaHFM",
  authDomain: "live-polls-d1ba7.firebaseapp.com",
  projectId: "live-polls-d1ba7",
  storageBucket: "live-polls-d1ba7.appspot.com",
  messagingSenderId: "489071699364",
  appId: "1:489071699364:web:549d1583d2325839648d31",
};
export const FIREBASE_VAPID_KEY =
  "BNa22ssPDs0JngeHaxHt0anNKIMBYG4VuDVghtaZ33cP2o_VXhlZNx7dDUtzY74cI6Z3wnLiXXAoOKmcb4-n1U8";

// Ui constants
export const TOAST_TIMEOUT = 4 * 1000; // 4 secs
