export const Answer = {
  initialValue: () => initialValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data) => toMap(data),
};

const initialValue = () => ({
  title: "",
  toQuestionId: "",
});

const fromJSON = (data) => ({
  id: data?.id,
  title: data?.text,
  toQuestionId: data?.toQuestionId,
});

const toMap = (data) => ({
  id: data?.id,
  text: data?.title,
  toQuestionId: data?.toQuestionId,
});
