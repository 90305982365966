import { Box, Pagination, Typography, styled } from "@mui/material";
import CustomCircularProgress from "../../../components/CustomCircularProgress";
import React, { useState, useEffect } from "react";
export default function SelectedCategory({ data, onVideoClick }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.items?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleVideoClick = (video) => {
    onVideoClick(video);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const StyledPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-page": {
      borderRadius: 0,
      color: "#A9A9A9",
      backgroundColor: "#EEEEEE",
      border: "1px solid #C8C8C8",
      transition: "0.5s all",
      width: "40px",
      height: "40px",
      "&:hover": {
        backgroundColor: "#eee",
        border: "1px solid #25AAE1",
      },
      "&.Mui-selected": {
        backgroundColor: "#25AAE1",
        color: "#fff",
      },
    },
    "& .MuiPaginationItem-icon": {
      color: "#6C6C6C",
      transition: "0.5s all",
      width: "40px",
      height: "40px",
      "&:hover": {
        color: "#25AAE1",
      },
      "&.Mui-disabled": {
        color: "#A9A9A9",
      },
    },
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "2em",
        gap: "2em",
      }}
    >
      <Typography variant="h4" sx={{ color: "#323232" }}>
        {data?.title}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          justifyContent: "center",
          alignItems: "center",
          gap: "2em",
        }}
      >
        {currentItems?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              outline: "1px solid #eeeeee",
              borderRadius: "10px",
              boxShadow: "0px 7px 15px #3C3C3C1A",
              cursor: "pointer",
              transition: "0.5s all",
            }}
            onClick={() => handleVideoClick("1")}
          >
            <img src={item?.poster} />

            <Box
              sx={{
                padding: "1em",
                display: "flex",
                gap: "1em",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#6C6C6C",
                }}
              >
                {item?.description}
              </Typography>
              <CustomCircularProgress
                value={item?.progressValue}
                color={item?.progressColor}
                size={55}
              />
            </Box>
          </Box>
        ))}
      </Box>

      {data && (
        <Box mt={2} display="flex" justifyContent="center">
          <StyledPagination
            count={Math.ceil(data?.items?.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
}
