import { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Checkbox } from "@mui/material";
import { LinearProgressWithLabel } from "./PostType";
import { cutStrings } from "../../../shared/cutStrings";

const Poll = ({ poll, choose, userId, creator, deleteChoice, postId }) => {
  const { t } = useTranslation(["events"]);

  const isCreator = creator?.id === userId;

  const chooseOption = useCallback(
    ({ pollId, optionId, questionId }) =>
      choose({ pollId, optionId, questionId }),
    [choose]
  );

  const deleteOption = useCallback(
    ({ pollId, questionId }) => deleteChoice({ pollId, questionId }),
    [deleteChoice]
  );

  return (
    <>
      {userId &&
      poll?.questions?.every((question) => question.myChosenOption) &&
      poll?.score ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Good job your score is:{" "}
            <Typography
              variant="h6"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              {poll?.score}
            </Typography>
          </Typography>
        </Box>
      ) : (
        <>
          {poll?.questions?.map((question, i) => {
            if (!question.myChosenOption && userId) {
              return (
                <Fragment key={i}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    sx={{ my: 2 }}
                  >
                    {question?.title && (
                      <Typography variant="body2">{question?.title}</Typography>
                    )}
                    {question?.options?.map((option, i) => {
                      return (
                        <Box
                          sx={{
                            width: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            key={i}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            sx={{ my: 1 }}
                          >
                            <Box>
                              <Checkbox
                                type="checkbox"
                                checked={false}
                                onChange={() => {}}
                                onClick={() => {
                                  chooseOption({
                                    pollId: poll.id,
                                    questionId: question.id,
                                    optionId: option.id,
                                  });
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography variant="body1">
                                {cutStrings(option.title, 80)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Fragment>
              );
            } else
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  sx={{ my: 2 }}
                >
                  {question?.title && (
                    <Typography variant="body2">{question?.title}</Typography>
                  )}
                  {question?.options?.map((option, i) => {
                    const isMyChosenOption =
                      option.id === question.myChosenOption?.optionId;
                    return (
                      <Box
                        key={i}
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="center"
                        flexDirection="column"
                        sx={{
                          my: 1,
                          cursor:
                            isMyChosenOption &&
                            poll?.pollType !== 3 &&
                            "pointer",
                        }}
                        width={1}
                        onClick={() => {
                          isMyChosenOption &&
                            poll?.pollType !== 3 &&
                            deleteOption({
                              pollId: poll.id,
                              questionId: question.id,
                              optionId: option.id,
                            });
                        }}
                      >
                        <Box sx={{ position: "relative", width: 1 }}>
                          <LinearProgressWithLabel
                            removePercent={
                              !(
                                isCreator ||
                                Boolean(poll?.viewResultsAfterInteraction)
                              )
                            }
                            value={
                              (isCreator ||
                                Boolean(poll?.viewResultsAfterInteraction)) &&
                              option?.percentage
                                ? option?.percentage
                                : isMyChosenOption
                                ? 100
                                : 0
                            }
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              top: -2,
                              left: 0,
                              px: 2,
                              py: 1,
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                sx={{ mr: 1 }}
                              >
                                {t("added_by")}
                              </Typography>
                              <Typography variant="subtitle2" color="primary">
                                {creator?.firstName + " " + creator?.lastName}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body2"
                              fontWeight="bold"
                              color="text"
                            >
                              {cutStrings(option.title, 80)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
          })}
        </>
      )}
    </>
  );
};

export default Poll;
