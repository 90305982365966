export const Vote = {
  initialValue: () => initialValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data) => toMap(data),
};

const initialValue = () => ({
  answerId: "",
  votes: 0,
});

const fromJSON = (data) => ({
  answerId: data?.answerId,
  votes: data?.votes,
});

const toMap = (data) => ({
  answerId: data?.answerId,
  votes: data?.votes,
});
