function Survey() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.55 16.854"
      stroke="none"
    >
      <g id="survey_1_" data-name="survey (1)" transform="translate(0 -46.049)">
        <path
          id="Path_15765"
          data-name="Path 15765"
          d="M450.044,354.81a.3.3,0,0,0-.418.079l-.691,1.013a2.1,2.1,0,0,0-.364,1.106l-.071,1.9a.3.3,0,0,0,.6.022l.071-1.9a1.5,1.5,0,0,1,.26-.789l.692-1.013A.3.3,0,0,0,450.044,354.81Z"
          transform="translate(-430.5 -296.32)"
        />
        <path
          id="Path_15766"
          data-name="Path 15766"
          d="M20.128,55.315,18.313,52.5V47.267A1.219,1.219,0,0,0,17.1,46.049H3.453a1.219,1.219,0,0,0-1.218,1.218v2.312a.3.3,0,1,0,.6,0V47.267a.616.616,0,0,1,.616-.616H17.1a.616.616,0,0,1,.616.616v9.094a.614.614,0,0,1-.051.244l-.612-.664V47.668a.353.353,0,0,0-.351-.354H14.652a.3.3,0,1,0,0,.6h1.794v7.372l-1-1.089a.928.928,0,0,0-1.285-.077H9.341a.3.3,0,0,0,0,.6h4.5a.927.927,0,0,0,.109.555l.239.433H6.364l.239-.433a.923.923,0,0,0,.069-.159l.1.075a.3.3,0,0,0,.407-.046l.911-1.059a.3.3,0,1,0-.456-.393l-.729.847-.383-.283a.917.917,0,0,0-.085-.1.929.929,0,0,0-1.332.035l-.661.717v-7h9a.3.3,0,1,0,0-.6H4.2a.353.353,0,0,0-.351.354v7.9l-.956,1.036a.614.614,0,0,1-.051-.244V50.782a.3.3,0,1,0-.6,0V52.5L.42,55.315a2.625,2.625,0,0,0,.038,2.908l1.159,1.7a1.5,1.5,0,0,1,.26.789l.071,1.9a.3.3,0,1,0,.6-.022l-.071-1.9a2.1,2.1,0,0,0-.364-1.106l-1.159-1.7a2.025,2.025,0,0,1-.029-2.242L2.236,53.61v2.751a1.218,1.218,0,0,0,.227.707l-.163.176a.3.3,0,1,0,.443.408L5.55,54.607a.327.327,0,0,1,.526.38l-1.688,3.05a.618.618,0,0,0,.064.7,1.5,1.5,0,0,1,.256,1.437,2.428,2.428,0,0,0-.135.745L4.541,62.31a.3.3,0,0,0,.6.014l.032-1.395a1.826,1.826,0,0,1,.1-.561,2.1,2.1,0,0,0-.36-2.018.02.02,0,0,1,0-.022l.415-.75h9.888l.415.75a.02.02,0,0,1,0,.022,2.1,2.1,0,0,0-.36,2.018,1.825,1.825,0,0,1,.1.56l.032,1.395a.3.3,0,1,0,.6-.014l-.032-1.395a2.426,2.426,0,0,0-.135-.745,1.5,1.5,0,0,1,.256-1.437.618.618,0,0,0,.064-.7l-1.688-3.05a.327.327,0,0,1,.526-.38l2.808,3.045a.3.3,0,1,0,.443-.408l-.163-.176a1.217,1.217,0,0,0,.227-.707V53.61l1.309,2.032a2.017,2.017,0,0,1,.132,1.959.3.3,0,0,0,.545.256,2.616,2.616,0,0,0-.171-2.541ZM5.663,56.977l.367-.663h8.488l.367.663Z"
        />
        <path
          id="Path_15767"
          data-name="Path 15767"
          d="M76.559,177.52v-.435a.3.3,0,0,0-.6,0v.435a.3.3,0,1,0,.6,0Z"
          transform="translate(-72.909 -125.489)"
        />
        <path
          id="Path_15768"
          data-name="Path 15768"
          d="M151.256,114.886a.3.3,0,0,0-.424.032l-.729.847-.44-.326a.3.3,0,1,0-.358.484l.665.493a.3.3,0,0,0,.407-.046l.911-1.059A.3.3,0,0,0,151.256,114.886Z"
          transform="translate(-143.196 -66.005)"
        />
        <path
          id="Path_15769"
          data-name="Path 15769"
          d="M231.247,128.008h-5.664a.3.3,0,1,0,0,.6h5.664a.3.3,0,0,0,0-.6Z"
          transform="translate(-216.241 -78.67)"
        />
        <path
          id="Path_15770"
          data-name="Path 15770"
          d="M231.247,187.616h-5.664a.3.3,0,1,0,0,.6h5.664a.3.3,0,0,0,0-.6Z"
          transform="translate(-216.241 -135.886)"
        />
        <path
          id="Path_15771"
          data-name="Path 15771"
          d="M155.377,174.435a.3.3,0,0,0-.426,0l-.32.32-.32-.32a.3.3,0,1,0-.426.426l.32.32-.32.32a.3.3,0,1,0,.426.426l.32-.32.32.32a.3.3,0,1,0,.426-.426l-.32-.32.32-.32A.3.3,0,0,0,155.377,174.435Z"
          transform="translate(-147.626 -123.149)"
        />
      </g>
    </svg>
  );
}

export default Survey;
