function Emoji3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.095 32.095">
      <g id="layer1" transform="translate(0)">
        <ellipse
          id="circle2912"
          cx="16.047"
          cy="16.047"
          rx="16.047"
          ry="16.047"
          transform="translate(0 0)"
          fill="#ffd42a"
        />
        <path
          id="path2914"
          d="M59.479,318.388a16.037,16.037,0,1,0,24.847,19.668q-.444.42-.918.806-.628.5-1.3.933t-1.392.8a16.021,16.021,0,0,1-1.464.655q-.748.291-1.522.506a16.023,16.023,0,0,1-1.566.351q-.791.136-1.593.193-.566.04-1.134.04-.8,0-1.6-.08a16.037,16.037,0,0,1-3.141-.637q-.767-.237-1.507-.55t-1.444-.7q-.7-.385-1.367-.838t-1.277-.971a16.083,16.083,0,0,1-2.233-2.3q-.5-.629-.933-1.3a16.04,16.04,0,0,1-.8-1.392q-.364-.715-.656-1.464t-.505-1.523q-.215-.773-.351-1.565t-.194-1.593q-.04-.566-.04-1.135a16.042,16.042,0,0,1,.08-1.6q.08-.8.239-1.586t.4-1.555q.237-.767.55-1.507t.7-1.444q.064-.106.129-.21Z"
          transform="translate(-54.324 -314.104)"
          fill="#ffbc2a"
        />
        <path
          id="path3046"
          d="M59.484,39.568a3.7,3.7,0,0,0-1.305,2.806,3.773,3.773,0,0,0,7.545,0,3.67,3.67,0,0,0-.2-1.19Z"
          transform="translate(-50.5 -32.801)"
          fill="#fff"
        />
        <ellipse
          id="ellipse3027"
          cx="1.409"
          cy="1.389"
          rx="1.409"
          ry="1.389"
          transform="translate(12.241 8.186)"
          fill="#1a1a1a"
        />
        <path
          id="path3049"
          d="M64.09,41.354a3.667,3.667,0,0,0-.132.955,3.773,3.773,0,0,0,7.545,0,3.692,3.692,0,0,0-1.161-2.676Z"
          transform="translate(-44.765 -32.736)"
          fill="#fff"
        />
        <ellipse
          id="ellipse3031"
          cx="1.409"
          cy="1.389"
          rx="1.409"
          ry="1.389"
          transform="translate(23.425 7.292)"
          fill="#1a1a1a"
        />
        <path
          id="path3033"
          d="M68.284,327.663s-5.647-3.2-8.848,0"
          transform="translate(-49.251 -306.313)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path3035"
          d="M57.9,319.384,68.412,322.2l9.188-2.462"
          transform="translate(-50.779 -313.116)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path2900"
          d="M64.968,328.459a6.237,6.237,0,0,0-4.565.118"
          transform="translate(-48.293 -304.505)"
          fill="none"
          stroke="#ffbc2a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
      </g>
    </svg>
  );
}

export default Emoji3;
