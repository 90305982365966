import { getInteractiveVideo } from "../../../services/InteractiveVideoService";
import {
  INTERACTIVE_LOADING,
  LOAD_INTERACTIVE,
  LOAD_INTERACTIVE_ERROR,
} from "../types";

export const getInteractiveAction = (id, isDemo) => (dispatch) => {
  dispatch({ type: INTERACTIVE_LOADING });

  return getInteractiveVideo(id, isDemo)
    .catch((e) =>
      dispatch({
        type: LOAD_INTERACTIVE_ERROR,
        payload: e,
      })
    )
    .then((d) => {
      if (d?.error) {
        return dispatch({
          type: LOAD_INTERACTIVE_ERROR,
          payload: d.error,
        });
      }
      dispatch({
        type: LOAD_INTERACTIVE,
        payload: d,
      });
    });
};
