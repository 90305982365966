import React, { Fragment } from "react";
import InteractiveButton from "./InteractiveButton";

function InteractiveAction({
  action,
  disabled,
  onClick,
  isSelected,
  animate,
  width,
  videoWidth,
  videoHeight,
  fullScreen,
}) {
  console.log(videoWidth, videoHeight, action.xFactor, action.yFactor);
  return (
    <Fragment>
      <InteractiveButton
        title={action.title}
        show={action?.show}
        disabled={disabled}
        x={action.xFactor * videoWidth}
        y={action.yFactor * videoHeight}
        onClick={onClick}
        isSelected={isSelected}
        animate={animate}
        textVariant={fullScreen?.active ? "h4" : "body1"}
        padding={fullScreen?.active ? "20px 60px" : "10px 30px"}
      />
    </Fragment>
  );
}

export default InteractiveAction;
