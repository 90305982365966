import React, { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";

const WheelComponent = ({
  segments,
  segColors,
  segImg,
  buttonIcon,
  logoIcon,
  result,
  data,
  frame,
  segSize,
  BackDataSize,
  // winningSegment,
  onFinished,
  primaryColor = "black",
  contrastColor = "white",
  buttonText = "Spin",
  isOnlyOnce = true,
  size = 360,
  upDuration = 100,
  downDuration = 50200,
  fontFamily = "proxima-nova",
  segmentFontSize = "1em",
  imgSize = 130,
  imgX = 25,
  imgY = -25,
  imgCenterX = 20,
  imgCenterY = 20,
}) => {
  let currentSegment = "";

  let isStarted = false;
  const [isFinished, setFinished] = useState(false);
  let timerHandle = 0;
  const timerDelay = segments.length + 45;
  let angleCurrent = 0;
  let angleDelta = 0;
  let canvasContext = null;
  let maxSpeed = 0;
  const upTime = segments.length * upDuration;
  const downTime = segments.length * downDuration;
  let spinStart = 0;
  let frames = 0;
  const centerX = 100;
  const centerY = 100;
  let progress = 0;
  var winningSegment = "";
  const soundRef = useRef();
  const playActionSound = () => {
    const audioPlayer = soundRef.current;
    audioPlayer?.play();
  };
  const stopActionSound = () => {
    const audioPlayer = soundRef.current;
    audioPlayer?.pause();
  };
  useEffect(() => {
    wheelInit();
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }, []);

  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };

  const initCanvas = () => {
    let canvas = document.getElementById("canvas");
    let button = document.getElementById("button");

    if (navigator.userAgent.indexOf("MSIE") !== -1) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("width", 1200);
      canvas.setAttribute("height", 800);
      canvas.setAttribute("id", "canvas");
      document.getElementById("wheel").appendChild(canvas);
    }
    if (result.length > 0) {
      // canvas.addEventListener("click", spin, false);
      button.addEventListener("click", spin, false);
    }

    canvasContext = canvas.getContext("2d");
  };
  const spin = () => {
    playActionSound();
    const randomIndex = Math.floor(Math.random() * result.length);

    // Get the object at the random index
    const randomObject = result[randomIndex];
    // console.log(randomObject);
    const index = data?.findIndex((arr) => arr.id === randomObject.id);

    winningSegment = index + 1;
    isStarted = true;
    if (timerHandle === 0) {
      spinStart = new Date().getTime();
      // maxSpeed = Math.PI / (segments.length * 2 + Math.random());
      maxSpeed = Math.PI / segments.length;
      frames = 0;
      timerHandle = setInterval(onTimerTick, timerDelay);
    }
  };

  const onTimerTick = () => {
    frames++;
    draw();
    const duration = new Date().getTime() - spinStart;

    let finished = false;
    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
    } else {
      if (winningSegment) {
        if (
          currentSegment === winningSegment &&
          frames >= segments.length * 3 &&
          progress >= 0.65999
        ) {
          console.log("win");
          progress = duration / upTime;
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
          finished = true;
        } else {
          progress = duration / downTime;
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        }
      } else {
        progress = duration / downTime;
        angleDelta =
          maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
      }
      if (progress >= 1) finished = true;
    }

    angleCurrent += angleDelta;
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;
    if (finished) {
      setFinished(true);
      onFinished(currentSegment - 1);
      clearInterval(timerHandle);
      timerHandle = 0;
      angleDelta = 0;
      progress = 0;
      stopActionSound();
    }
  };

  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const drawSegment = (key, lastAngle, angle) => {
    const ctx = canvasContext;
    const valueText = segments[key];
    const value = segImg[key];
    const newImg = new Image();
    newImg.src = value;
    newImg.classList.add("rotate");
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle = segColors[key];
    ctx.fill();
    ctx.stroke();
    ctx.save();
    newImg.onload = () => {
      ctx.translate(centerX, centerY);
      ctx.rotate((lastAngle + angle) / 2);
      newImg && ctx.drawImage(newImg, imgX, imgY, imgSize, imgSize);
      ctx.fillStyle = contrastColor;
      // ctx.font = "bold " + segmentFontSize + " " + fontFamily;

      // ctx.fillText(valueText.substr(0, 21), size / 2 + 80, 0);
      ctx.restore();
    };
  };

  const drawWheel = () => {
    const ctx = canvasContext;
    let lastAngle = angleCurrent;
    const len = segments.length;
    const PI2 = Math.PI * 2;
    ctx.lineWidth = 1;
    ctx.strokeStyle = primaryColor;
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = "1em " + fontFamily;
    for (let i = 0; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;

      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    // Draw a center circle
    ctx.beginPath();
    // ctx.arc(centerX, centerY, 28, 0, PI2, false);
    ctx.closePath();
    ctx.fillStyle = primaryColor;
    ctx.lineWidth = 10;
    ctx.strokeStyle = "white";
    ctx.fill();
    ctx.font = "bold 1em " + fontFamily;
    ctx.fillStyle = contrastColor;
    ctx.textAlign = "center";
    ctx.fillStyle = "black";
    const newLogo = new Image();
    newLogo.src = logoIcon;

    newLogo.onload = () => {
      newLogo && ctx.drawImage(newLogo, 86, 86, 30, 30);
    };
    ctx.stroke();
    // ctx.fillText("buttonText", centerX, centerY + 3);
    // let img = `<img src=${logo} alt="" width={160} style={{ transform: ' rotate(-0.25turn)' }}/>`;

    // Draw outer circle

    ctx.beginPath();
    // ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();
    ctx.lineWidth = 10;
    ctx.strokeStyle = primaryColor;
    const newFrame = new Image();
    newFrame.src = frame;

    newFrame.onload = () => {
      newFrame && ctx.drawImage(newFrame, -3, -3, 210, 210);
    };
    ctx.stroke();
  };

  const drawNeedle = () => {
    const ctx = canvasContext;
    ctx.lineWidth = 1;
    ctx.strokeStyle = contrastColor;
    ctx.fileStyle = contrastColor;
    ctx.beginPath();
    ctx.moveTo(centerX + 10, centerY - 10);
    ctx.lineTo(centerX - 10, centerY - 5);
    ctx.lineTo(centerX, centerY - 30);
    ctx.closePath();
    ctx.fill();
    const change = angleCurrent + Math.PI / 2;
    let i =
      segments.length -
      Math.floor((change / (Math.PI * 2)) * segments.length) -
      1;

    if (i < 0) i = i + segments.length;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = primaryColor;
    ctx.font = "bold 1.5em " + fontFamily;
    currentSegment = i + 1;
    // isStarted &&
    //   ctx.fillText(currentSegment, centerX + 10, centerY + size + 50);
  };

  const clear = () => {
    const ctx = canvasContext;
    ctx.clearRect(0, 0, 1000, 800);
  };

  return (
    <>
      <Box
        id="wheel"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          py: 3,
          position: "relative",
          flexDirection: "column",
        }}
      >
        <audio ref={soundRef}>
          <source src={`${process.env.PUBLIC_URL}/assets/sounds/spin.mpeg`} />
        </audio>

        <canvas
          id="canvas"
          width="215"
          height="215"
          style={{
            zIndex: 1,
            pointerEvents: isFinished && isOnlyOnce ? "none" : "auto",
          }}
        />

        <Box
          sx={{
            left: "50%",
            bottom: 0,
            position: "absolute",
            // bgcolor: "#ddd",
            height: "15vh",
            width: "90%",
            borderRadius: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: "translate(-50%, 0%)",
          }}
        >
          {/* <Box
            sx={{
              background: "linear-gradient(90deg,#fff, gray 50%, #fff);",
              height: "7vh",
              width: "100%",
              borderRadius: "50%",
            }}
          ></Box> */}
        </Box>
      </Box>
      <Button
        sx={{ justifyContent: "center", display: "flex", py: 3 }}
        fullWidth
        id="button"
        color="secondary"
        variant="contained"
      >
        start
      </Button>
    </>
  );
};
export default WheelComponent;
