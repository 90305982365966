import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { FadeInUp } from "../../../config/animations";

function InteractiveButton({
  onClick,
  padding = "10px 30px",
  margin = "5px 8px",
  variant = "contained",
  textVariant = "body1",
  size = "medium",
  title,
  className,
  style,
  type,
  disabled = false,
  isSelected = false,
  show = true,
  animate = true,
  mobileView = false,
  x = 0,
  y = 0,
  width,
}) {
  console.log(y, x);
  return (
    <Box sx={{ position: "absolute", bottom: y, left: x }}>
      {/* {show && ( */}
      <FadeInUp animate={animate}>
        <Button
          type={type}
          variant={variant}
          color={isSelected ? "primary" : "secondary"}
          className={className}
          size={size}
          style={{
            padding: padding,
            margin: margin,
            borderRadius: 10,
            transition: "all .4s",
            ...style,
          }}
          onClick={onClick}
          // disabled={disabled}
        >
          <Typography
            className={"font-weight-semibold"}
            variant={textVariant}
            color="inherit"
          >
            {title}
          </Typography>
        </Button>
      </FadeInUp>
      {/* )} */}
    </Box>
  );
}

export default InteractiveButton;
