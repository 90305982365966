import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import useIsWidthUp from "../../../hook/useIsWidthUp";

export default function PlusOne({}) {
  const theme = useTheme();
  // const t = useTranslate();

  const [right, _] = useState(Math.floor(Math.random() * 9) * 10 + "%");

  const isLargeScreen = useIsWidthUp("lg");

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1000,
        animation:
          "floating-heart 5s cubic-bezier(0.5, 0.5, 0.5, 0.5) forwards",

        backgroundColor: theme.palette.success.light,
        borderRadius: 100,
        width: 50,
        height: 50,
        marginBottom: isLargeScreen ? 125 : 0,
        right: right,
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <Typography textAlign="center" fontWeight={600}>
        +1
      </Typography>
    </Box>
  );
}
