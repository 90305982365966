function Competition() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" fill="none">
      <defs>
        <clipPath id="clip-path">
          <path
            id="path4878"
            d="M0-682.665H23v23H0Z"
            transform="translate(0 682.665)"
          />
        </clipPath>
      </defs>
      <g id="g4872" transform="translate(0 682.665)">
        <g id="g4874" transform="translate(0 -682.665)">
          <g id="g4876" clipPath="url(#clip-path)">
            <path
              id="path4882"
              d="M13.333-229.786h6.738v-7.547H13.333Z"
              transform="translate(-12.884 252.337)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
        <g id="g4884" transform="translate(6.064 -671.075)">
          <path
            id="path4886"
            d="M0,0H10.871"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="g4888" transform="translate(0 -682.665)">
          <g id="g4890" clipPath="url(#clip-path)">
            <g id="g4896" transform="translate(7.003 1.198)">
              <path
                id="path4898"
                d="M-128.971-197.644l-2.207,1.611a.471.471,0,0,1-.725-.527l.85-2.6-2.218-1.6a.471.471,0,0,1,.276-.853h2.738l.839-2.595a.471.471,0,0,1,.9,0l.839,2.595h2.738a.471.471,0,0,1,.276.853l-2.218,1.6.85,2.6a.471.471,0,0,1-.725.527Z"
                transform="translate(133.467 204.535)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g4900" transform="translate(11.078 15.094)">
              <path
                id="path4902"
                d="M0,0H.845V3.953"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g4904" transform="translate(7.187 11.59)">
              <path
                id="path4906"
                d="M-247.374-264.124v1.752H-256v-10.961h8.625v5.166"
                transform="translate(255.999 273.333)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g4908" transform="translate(14.487 0.449)">
              <path
                id="path4910"
                d="M0-42.971l1.5-1.5"
                transform="translate(0 44.469)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g4912" transform="translate(6.97 0.449)">
              <path
                id="path4914"
                d="M-42.971-42.971l-1.5-1.5"
                transform="translate(44.469 44.469)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g4916" transform="translate(16.155 7.467)">
              <path
                id="path4918"
                d="M0,0,1.654.792"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g4920" transform="translate(5.191 7.467)">
              <path
                id="path4922"
                d="M-47.44,0l-1.654.792"
                transform="translate(49.095)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g4924" transform="translate(15.812 15.004)">
              <path
                id="path4926"
                d="M0,0H6.738V7.547H0"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
            <g id="g4928" transform="translate(15.812 18.777)">
              <path
                id="path4930"
                d="M0,0H0"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Competition;
