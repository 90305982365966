import { Box, Typography } from "@mui/material";

function Adventure() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        padding: "2em",
        gap: "1em",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "#323232",
        }}
      >
        Adventure
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#7A7A7A",
        }}
      >
        Adventure stories follow a certain formula. They include a hero who is
        going on a journey or quest in an unfamiliar environment. For example,
        in Lord Of The Rings, the ‘hero’ (Frodo) leaves his hometown and sets
        off on a quest to destroy the ring in the fires of Mount Doom.
      </Typography>
    </Box>
  );
}

export default Adventure;
