import VideoPlayer from "./components/VideoPlayer";
import Adventure from "./components/Adventure";
import Comments from "./components/Comments";
import RelatedVideos from "./components/RelatedVideos";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInteractiveAction } from "../../store/actions/interactive";
import { getPostsAction } from "../../store/actions/poll";

function PyxizTV() {
  const dispatch = useDispatch();

  const fetchPosts = useCallback(
    ({ page, next }) => {
      getPostsAction({
        page,
        limit: 6,
        pageId: 141,
        next,
        type: "polls",
        id: 2113,
      })(dispatch);
    },
    [dispatch]
  );
  const fetchData = async ({ id, demo }) => {
    await getInteractiveAction(id, demo)(dispatch);
  };
  useEffect(() => {
    fetchData({ id: 14 });
    fetchPosts({ page: 1 });
  }, []);

  return (
    <>
      <VideoPlayer />
      <Adventure />
      <Comments />
      <RelatedVideos />
    </>
  );
}

export default PyxizTV;
