function Crown({ active, completed }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8.755"
      viewBox="0 0 12 8.755"
    >
      <g id="crown" transform="translate(-2.977 -6.493)">
        <path
          id="Path_16050"
          data-name="Path 16050"
          d="M14.964,9.877l-1.133,5.007a.461.461,0,0,1-.461.364H4.545a.461.461,0,0,1-.461-.364l-1.1-5.007a.461.461,0,0,1,.654-.511l2.69,1.3L8.561,6.727a.461.461,0,0,1,.8,0l2.229,3.948L14.3,9.361a.461.461,0,0,1,.663.516Z"
          fill={active ? "#F28499" : completed ? "#EA5069" : "#7a7a7a"}
        />
      </g>
    </svg>
  );
}

export default Crown;
