function SendReact() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.32 18.028">
      <g
        id="Icon_feather-smile"
        data-name="Icon feather-smile"
        transform="translate(-2.5 -2.5)"
      >
        <path
          id="Path_15741"
          data-name="Path 15741"
          d="M20.32,11.514a8.588,8.588,0,0,1-8.66,8.514A8.588,8.588,0,0,1,3,11.514,8.588,8.588,0,0,1,11.66,3,8.588,8.588,0,0,1,20.32,11.514Z"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_15742"
          data-name="Path 15742"
          d="M12,21a4.721,4.721,0,0,0,3.5,1.749A4.721,4.721,0,0,0,19,21"
          transform="translate(-3.838 -7.814)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_15743"
          data-name="Path 15743"
          d="M13.5,13.5h.009"
          transform="translate(-4.438 -4.54)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_15744"
          data-name="Path 15744"
          d="M22.5,13.5h.009"
          transform="translate(-8.242 -4.54)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default SendReact;
