function NotificationBell() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 24 28.364"
    >
      <path
        id="Icon_ionic-md-notifications-outline"
        data-name="Icon ionic-md-notifications-outline"
        d="M17.693,8.316a9.857,9.857,0,0,1,1.63.305,6.948,6.948,0,0,1,5.3,6.808v8.7l.636.638.531.533H9.464l.531-.533.636-.638v-8.7a6.948,6.948,0,0,1,5.3-6.808,12.07,12.07,0,0,1,1.63-.305m.068-4.941A2.084,2.084,0,0,0,15.507,5.5v.993A9.192,9.192,0,0,0,8.448,15.43v7.8L5.625,26.066v1.418h24V26.066L26.8,23.23v-7.8a9.192,9.192,0,0,0-7.059-8.935V5.5a2.084,2.084,0,0,0-2.118-2.127ZM20.448,28.9H14.8a2.824,2.824,0,1,0,5.647,0Z"
        transform="translate(-5.625 -3.375)"
      />
    </svg>
  );
}

export default NotificationBell;
