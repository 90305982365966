import { useState, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  Popover,
} from "@mui/material";

import {
  PlayArrow,
  MoreHoriz,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

import PlayBtn from "../../../SVGs/PlayBtn";
import CustomCircularProgress from "../../../components/CustomCircularProgress";

export default function VideoPlayer() {
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const videoRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState({
    name: "",
    imageSrc: "",
  });

  const handleVideoPlay = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setIsVideoPaused(false);
    videoRef.current.play();
  };

  const handleVideoPause = () => {
    setIsVideoPaused(true);
    videoRef.current.pause();
  };

  const handleIconButtonClick = (name, event) => {
    setPopoverData({
      name: name,
    });
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  let starringData = [
    {
      image: "/images/mona.png",
      name: "Mona",
    },
    {
      image: "/images/yara.png",
      name: "Yara",
    },
    {
      image: "/images/nanci.png",
      name: "Nanci",
    },
    {
      image: "/images/soha.png",
      name: "Soha",
    },
    {
      image: "/images/shourok.png",
      name: "Shourok",
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <video
          controls={!isVideoPaused}
          src="./images/videoTest.mp4"
          poster={"/images/homeVideoPoster.png"}
          style={{
            width: "100%",
            objectFit: "cover",
          }}
          onPlay={handleVideoPlay}
          onPause={handleVideoPause}
          ref={videoRef}
        />

        {isVideoPaused && (
          <Box
            sx={{
              display: "flex",
              left: "0",
              right: "0",
              top: "0",
              bottom: "0",
              position: "absolute",
              justifyContent: "space-between",
              alignItems: "center",
              "& > *": {
                color: "#ffffff",
              },
            }}
          >
            <IconButton>
              <KeyboardArrowLeft
                sx={{
                  color: "#ffffff",
                  fontSize: { xs: "1.5em", sm: "2em" },
                  transition: "0.5s all",
                  "&: hover": {
                    color: "#EA5069",
                    bgcolor: "transparent",
                  },
                  zIndex: 10,
                }}
              />
            </IconButton>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  flexDirection: "column",
                  zIndex: "5",
                  width: { md: "70%", lg: "50%" },
                  justifyContent: "space-between",
                  alignItems: "start",
                  gap: { sm: "0.5em", md: "1em", xl: "2em" },
                }}
              >
                <Typography
                  sx={{
                    pointerEvents: "none",
                    color: "#ffffff",
                    fontSize: {
                      xs: "10",
                      sm: "12px",
                      md: "15px",
                      lg: "20px",
                      xl: "30px",
                    },
                  }}
                >
                  TV Service
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1em",
                    pointerEvents: "none",
                    "& button": {
                      color: "#ffffff",
                      backgroundColor: "#A9A9A9",
                      fontSize: {
                        xs: "10px",
                        md: "12px",
                        lg: "15px",
                        xl: "18px",
                      },
                    },
                  }}
                >
                  <Button size="small">PG-13</Button>
                  <Button size="small">HD</Button>
                </Box>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: {
                      xs: "15",
                      sm: "20px",
                      md: "30px",
                      lg: "40px",
                      xl: "60px",
                    },
                    pointerEvents: "none",
                  }}
                >
                  Seven Days in Ibiza
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1em",
                    color: "#eeeeee",
                    pointerEvents: "none",
                  }}
                >
                  <Typography variant="caption">
                    15 August, 2016 (UK)
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#eeeeee",
                      width: "1px",
                      height: "100%",
                    }}
                  />
                  <Typography variant="caption">163 min</Typography>
                  <Box
                    sx={{
                      backgroundColor: "#eeeeee",
                      width: "1px",
                      height: "100%",
                    }}
                  />
                  <Typography variant="caption">Documentary</Typography>
                </Box>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: {
                      xs: "12px",
                      sm: "8px",
                      md: "12px",
                      lg: "18px",
                      xl: "22px",
                    },
                  }}
                >
                  A sex columnist, Carrie Bradshaw, and her three friends
                  Samantha, Charlotte and Miranda explore Manhattan's dating
                  scene, chronicling the mating habits of single New Yorkers.
                </Typography>
                <Box
                  sx={{
                    display: { xs: "flex" },
                    gap: "1em",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#EA5069",
                      boxShadow: "0px 7px 15px #EA506940",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#ffffff",
                      gap: "0.5em",
                      textTransform: "none",
                      fontFamily: "RobotoMedium",
                      fontSize: "1rem",
                      transition: "0.5s all",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "#EA5069",
                      },
                    }}
                    onClick={handleVideoPlay}
                  >
                    <PlayArrow />
                    Watch Now
                  </Button>
                  <IconButton
                    sx={{
                      transition: "0.5s all",
                      outline: "2px solid #ffffff",
                      color: "#ffffff",
                      "&:hover": {
                        color: "#EA5069",
                        outline: "2px solid #EA5069",
                      },
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ textTransform: "uppercase" }}>
                    STARRING
                  </Typography>
                  <Box>
                    {starringData?.map((item, index) => (
                      <IconButton
                        key={index}
                        onClick={(event) =>
                          handleIconButtonClick(item.name, event)
                        }
                      >
                        <Avatar src={item.image} />
                      </IconButton>
                    ))}
                  </Box>
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box
                    sx={{
                      padding: { xs: "0.5em", sm: "1em" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: { xs: "0.5em", sm: "1em" },
                    }}
                  >
                    <Typography>{popoverData.name}</Typography>
                    <Button
                      sx={{
                        backgroundColor: "#FBBF14",
                        color: "#273238",
                        fontFamily: "RobotoMedium",
                        fontSize: "1rem",
                        height: "2em",
                        "&: hover": {
                          color: "#FBBF14",
                          backgroundColor: "#273238",
                        },
                      }}
                    >
                      IMDb
                    </Button>
                  </Box>
                </Popover>
              </Box>
              <Box
                sx={{
                  zIndex: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={handleVideoPlay}
                  sx={{
                    fill: "#f9f9f9",
                    stroke: "#fff",
                    transition: "0.5s all",
                    "&: hover": {
                      bgcolor: "none",
                      fill: "#EA5069",
                      stroke: "#EA5069",
                      bgcolor: "transparent",
                    },
                    width: {
                      xs: "60px",
                      sm: "70px",
                      md: "80px",
                      lg: "90px",
                      xl: "110px",
                    },
                  }}
                >
                  <PlayBtn />
                </IconButton>
              </Box>
            </Box>

            <IconButton
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
            >
              <KeyboardArrowRight
                sx={{
                  color: "#ffffff",
                  fontSize: { xs: "1.5em", sm: "2em" },
                  transition: "0.5s all",
                  "&: hover": {
                    color: "#EA5069",
                    bgcolor: "transparent",
                  },
                }}
              />
            </IconButton>
          </Box>
        )}

        {isVideoPaused && (
          <>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                pointerEvents: "none",
              }}
            >
              <img
                src="/images/homeVideoBgd.svg"
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                padding: { sm: "1em", md: "2em", lg: "3em" },
                display: { xs: "none", sm: "block" },
              }}
            >
              <CustomCircularProgress
                value={86}
                color={"green"}
                size={60}
                valueColor={"white"}
                valueSize={"20px"}
              />
            </Box>
          </>
        )}

        {!isVideoPaused && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
            onClick={handleVideoPause}
          ></Box>
        )}
      </Box>

      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          flexDirection: "column",
          gap: "1em",
          padding: "1em",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            sx={{
              pointerEvents: "none",
            }}
          >
            TV Service
          </Typography>
          <CustomCircularProgress
            value={86}
            color={"green"}
            size={40}
            valueSize={"15px"}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "1em",
            pointerEvents: "none",
            "& button": {
              backgroundColor: "#A9A9A9",
              fontSize: "12px",
              color: "white",
            },
          }}
        >
          <Button size="small">PG-13</Button>
          <Button size="small">HD</Button>
        </Box>
        <Typography
          variant="h4"
          sx={{
            pointerEvents: "none",
          }}
        >
          Seven Days in Ibiza
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "1em",
            pointerEvents: "none",
          }}
        >
          <Typography variant="caption">15 August, 2016 (UK)</Typography>
          <Box
            sx={{
              backgroundColor: "black",
              width: "1px",
            }}
          />
          <Typography variant="caption">163 min</Typography>
          <Box
            sx={{
              backgroundColor: "black",
              width: "1px",
            }}
          />
          <Typography variant="caption">Documentary</Typography>
        </Box>
        <Typography variant="body1">
          A sex columnist, Carrie Bradshaw, and her three friends Samantha,
          Charlotte and Miranda explore Manhattan's dating scene, chronicling
          the mating habits of single New Yorkers.
        </Typography>
        <Box
          sx={{
            display: { xs: "flex" },
            gap: "1em",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#EA5069",
              boxShadow: "0px 7px 15px #EA506940",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
              gap: "0.5em",
              textTransform: "none",
              fontFamily: "RobotoMedium",
              fontSize: "1rem",
              transition: "0.5s all",
              "&:hover": {
                backgroundColor: "white",
                color: "#EA5069",
              },
            }}
            onClick={handleVideoPlay}
          >
            <PlayArrow />
            Watch Now
          </Button>
          <IconButton
            sx={{
              transition: "0.5s all",
              outline: "2px solid #ffffff",
              color: "#ffffff",
              "&:hover": {
                color: "#EA5069",
                outline: "2px solid #EA5069",
              },
            }}
          >
            <MoreHoriz />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexDirection: "column",
          }}
        >
          <Typography sx={{ textTransform: "uppercase" }}>STARRING</Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {starringData?.map((item, index) => (
              <IconButton
                key={index}
                onClick={(event) => handleIconButtonClick(item.name, event)}
              >
                <Avatar src={item.image} />
              </IconButton>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
