export default function Movies() {
  return (
    <svg
      id="g855"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.932 42.932"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            id="path861"
            d="M0-682.665H42.932v42.932H0Z"
            transform="translate(0 682.665)"
          />
        </clipPath>
      </defs>
      <g id="g857">
        <g id="g859" clipPath="url(#clip-path)">
          <g id="g865" transform="translate(0.629 0.629)">
            <path
              id="path867"
              d="M-574.834-411.24l1.926,2.021a4.144,4.144,0,0,1-2.981,7.024h-30.4v4.863m-2.516-4.863h-2.515v5.031h33.291a4.086,4.086,0,0,0,3.815-2.525h0l2.136-5.031m-.885-4.449-3.517,3.4m.377,7.98v-4.234m-5.031,4.7v-4.7m-5.031,4.7v-4.7m-5.031,4.7v-4.7m-5.031,4.7v-4.7m-5.031,4.7v-4.7m27.148-.665a4.167,4.167,0,0,0-.938-1.5l-4.15-4.316m-24.925,1.913c3.011,2.187,6.388,2.671,7.543,1.081s.558-7.254-1.809-8.974-7.938-.538-9.094,1.053S-607.131-408.779-604.12-406.591Zm19.823-23.683c-1.87.607-4.716,5.541-3.812,8.323s6.106,5.1,7.976,4.493,2.453-3.969,1.3-7.508S-582.428-430.882-584.3-430.274Zm-18.716,10.969a5.764,5.764,0,0,0,2.2-2.646c.9-2.783-1.942-7.716-3.811-8.323s-4.318,1.769-5.467,5.308-.567,6.9,1.3,7.508a5.925,5.925,0,0,0,3.586-.614m23.76,4.641c-1.156-1.59-6.727-2.773-9.094-1.053s-2.964,7.383-1.809,8.974,4.533,1.107,7.543-1.081S-580.286-411.841-581.441-413.432Zm-13.019-22.891c-3.721,0-6.738,1.594-6.738,3.56s3.812,6.2,6.738,6.2,6.738-4.231,6.738-6.2S-590.739-436.322-594.46-436.322Zm13.712,18.884a13.857,13.857,0,0,0-.465-6.763,13.857,13.857,0,0,0-3.578-5.724m-7.752,23.822a13.858,13.858,0,0,0,6.288-2.532,13.857,13.857,0,0,0,4.338-5.171m-25.052-.011a13.857,13.857,0,0,0,4.351,5.2,13.858,13.858,0,0,0,6.259,2.528m-7.731-23.829a13.856,13.856,0,0,0-3.6,5.744,13.857,13.857,0,0,0-.47,6.733m13.7-3.7a1.258,1.258,0,0,0-1.258,1.258,1.258,1.258,0,0,0,1.258,1.258,1.258,1.258,0,0,0,1.258-1.258A1.258,1.258,0,0,0-594.46-421.145Zm6.575-11.014a13.857,13.857,0,0,0-6.575-1.648,13.859,13.859,0,0,0-6.549,1.634m13.125.014a13.857,13.857,0,0,0-6.575-1.648,13.859,13.859,0,0,0-6.549,1.634m-.324,29.951a18.957,18.957,0,0,1-12.077-17.665,18.95,18.95,0,0,1,18.95-18.95,18.861,18.861,0,0,1,10.161,2.952m2.027,1.487a18.91,18.91,0,0,1,6.762,14.511,18.957,18.957,0,0,1-12.147,17.693"
              transform="translate(613.411 438.838)"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
