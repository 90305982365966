export const SnipperItem = {
  initialValue: () => initialValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data) => toMap(data),
};
const initialValue = () => ({
  title: "",
  color: "",
  img: "",
  size: "",
  limit: "",
  wheelId: "",
});
const fromJSON = (data) => ({
  id: data?.id,
  title: data?.name,
  color: data?.color,
  img: data?.image,
  size: data?.size,
  limit: data?.limit,
  wheelId: data?.wheelId,
});

const toMap = (data) => ({
  title: data?.name,
  color: data?.color,
  img: data?.image,
  size: data?.size,
  limit: data?.limit,
  wheelId: data?.wheelId,
});
