function Live() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.756 28.993">
      <g id="live" transform="translate(-6.5 -44.096)">
        <path
          id="Path_15731"
          data-name="Path 15731"
          d="M6.5,44.1H90.256V73.089H6.5Z"
          fill="#e01111"
        />
        <g
          id="Group_65255"
          data-name="Group 65255"
          transform="translate(15.015 50.587)"
        >
          <path
            id="Path_15732"
            data-name="Path 15732"
            d="M53.984,66.362v2.657H42.868V53.009h2.89V66.362Z"
            transform="translate(-24.896 -53.009)"
            fill="#f2f2f2"
          />
          <path
            id="Path_15733"
            data-name="Path 15733"
            d="M61.428,53.009h2.893v16.01H61.428Z"
            transform="translate(-29.938 -53.009)"
            fill="#f2f2f2"
          />
          <path
            id="Path_15734"
            data-name="Path 15734"
            d="M84.988,53.009,78.3,69.019H75.06l-6.689-16.01h3.216l5.081,12.142,5.081-12.142Z"
            transform="translate(-31.825 -53.009)"
            fill="#f2f2f2"
          />
          <path
            id="Path_15735"
            data-name="Path 15735"
            d="M97.013,55.666v3.869h8.273v2.656H97.013v4.172h9.135V69.02H94.1V53.009h12.049v2.657Z"
            transform="translate(-38.815 -53.009)"
            fill="#f2f2f2"
          />
          <circle
            id="Ellipse_1087"
            data-name="Ellipse 1087"
            cx="4.37"
            cy="4.37"
            r="4.37"
            transform="translate(0 3.635)"
            fill="#f2f2f2"
          />
        </g>
      </g>
    </svg>
  );
}

export default Live;
