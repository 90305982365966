function Emoji8() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.095 32.095">
      <g
        id="Group_13356"
        data-name="Group 13356"
        transform="translate(-1894.06 -495.445)"
      >
        <g id="layer1" transform="translate(1894.06 495.445)">
          <ellipse
            id="circle2752"
            cx="16.047"
            cy="16.047"
            rx="16.047"
            ry="16.047"
            transform="translate(0 0)"
            fill="#ffd42a"
          />
          <path
            id="path2754"
            d="M59.479,300.5a16.037,16.037,0,1,0,24.847,19.668q-.444.42-.918.806-.628.5-1.3.933a16.042,16.042,0,0,1-1.392.8q-.715.364-1.464.655t-1.522.507q-.774.215-1.566.351t-1.593.193q-.566.04-1.134.04c-.534,0-1.069-.027-1.6-.08a16.046,16.046,0,0,1-1.587-.24q-.787-.16-1.555-.4t-1.507-.55q-.739-.313-1.444-.7t-1.367-.838q-.662-.453-1.277-.971a16.036,16.036,0,0,1-1.174-1.093q-.56-.576-1.059-1.205t-.933-1.3a16.036,16.036,0,0,1-.8-1.392q-.364-.715-.656-1.464t-.505-1.523q-.215-.773-.351-1.565t-.194-1.593q-.04-.566-.04-1.135,0-.8.08-1.6t.239-1.586q.16-.787.4-1.554a16.041,16.041,0,0,1,.55-1.507q.313-.739.7-1.444.064-.105.129-.21Z"
            transform="translate(-54.324 -296.217)"
            fill="#ffbc2a"
          />
          <path
            id="path2774"
            d="M59.909,309.78s9.311-4.592,10.542,0"
            transform="translate(-48.783 -289.035)"
            fill="none"
            stroke="#1a1a1a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.058"
          />
          <path
            id="path2776"
            d="M61.846,310.415s1.29-1.483,3.859,0"
            transform="translate(-46.861 -287.035)"
            fill="none"
            stroke="#ffbc2a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.058"
          />
          <path
            id="path2780"
            d="M57.83,302.717h5.93"
            transform="translate(-50.845 -294.019)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.058"
          />
          <path
            id="path2782"
            d="M61.533,302.717H60.075"
            transform="translate(-48.619 -294.019)"
            fill="none"
            stroke="#1a1a1a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.058"
          />
          <path
            id="path2784"
            d="M64.185,302.717h5.93"
            transform="translate(-44.54 -294.019)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.058"
          />
          <path
            id="path2786"
            d="M67.888,302.717H66.429"
            transform="translate(-42.314 -294.019)"
            fill="none"
            stroke="#1a1a1a"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.058"
          />
        </g>
      </g>
    </svg>
  );
}

export default Emoji8;
