function Emoji9() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.094 27.905">
      <g
        id="_1646933_like_thumbs_up_icon"
        data-name="1646933_like_thumbs up_icon"
        transform="translate(-2.094 -3)"
      >
        <rect
          id="Rectangle_2949"
          data-name="Rectangle 2949"
          width="5"
          height="16"
          transform="translate(2.094 14.452)"
          fill="#25aae1"
        />
        <path
          id="Path_11298"
          data-name="Path 11298"
          d="M26.7,12.849H18.49V4.641A1.641,1.641,0,0,0,16.849,3H15.207L8.091,11.824A4.925,4.925,0,0,0,7,14.915V27.622A3.283,3.283,0,0,0,10.283,30.9H20.845a4.924,4.924,0,0,0,4.416-2.746l4.719-9.565V16.132A3.283,3.283,0,0,0,26.7,12.849Z"
          transform="translate(3.207)"
          fill="#25aae1"
        />
      </g>
    </svg>
  );
}

export default Emoji9;
