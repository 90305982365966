import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { FIREBASE_CONFIG, FIREBASE_VAPID_KEY } from "./constants";

const app = initializeApp(FIREBASE_CONFIG);

export const getFcmToken = async () => {
  const messaging = getMessaging(app);
  const currentToken = await getToken(messaging, {
    vapidKey: FIREBASE_VAPID_KEY,
  });

  if (currentToken) {
    return currentToken;
  } else {
    await Notification.requestPermission();
  }
};
