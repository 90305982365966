import { useEffect, useState } from "react";
import PollQuestion from "./Polls";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";
import { setSession } from "../../../store/actions/authPoll";
import { AuthService } from "../../../services/AuthService";
import { PollsService } from "../../../services/PollsService";
import useIsWidthUp from "../../../hook/useIsWidthUp";

export default function PollDisplay({ width, livePoll }) {
  const [poll, setPoll] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState();
  const auth = useSelector((s) => s.authPoll);

  useEffect(() => {
    if (auth.isLoggedIn) {
      setSession(auth);
    } else {
      AuthService.getUserToken();
    }
  }, []);

  let pollId = 1;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const data = await PollsService.fetchPoll(pollId);
      setPoll(data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const updateCurrentQuestion = (newId) => {
    PollsService.goToQuestion(pollId, newId);
    setCurrentQuestionId(newId);
  };
  const isLargeScreen = useIsWidthUp("sm");

  return (
    <>
      {isLoading ? (
        <Box className="my-5 d-flex align-items-center justify-content-center">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            zIndex: 11,
          }}
        >
          {poll?.questions?.length == 0 ? (
            <Box className="my-5 py-5 d-flex align-items-center justify-content-center">
              <Typography>default_err_msg</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                position: "absolute",
                bottom: "20%",
                left: "18%", 
              }}
            >
              <PollQuestion
                question={poll?.questions?.find(
                  (e) => e?.id == currentQuestionId
                )}
                setCurrentQuestion={updateCurrentQuestion}
                livePoll={livePoll}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
