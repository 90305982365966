import { useState } from "react";
import { Box, Divider } from "@mui/material";
import VideoPlayer from "./components/VideoPlayer";
import CategoriesBar from "./components/CategoriesBar";
import SelectedCategory from "./components/SelectedCategory";

export default function TVServices({ onVideoClick }) {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryClick = (category) => {
    let dummyData = category;
    if (category) {
      dummyData = {
        title: category,
        items: [
          {
            poster: "/images/thirdVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 30,
            progressColor: "green",
          },
          {
            poster: "/images/fourthVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 40,
            progressColor: "purple",
          },
          {
            poster: "/images/firstVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 80,
            progressColor: "blue",
          },
          {
            poster: "/images/secondVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 67,
            progressColor: "red",
          },
          {
            poster: "/images/thirdVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 30,
            progressColor: "green",
          },
          {
            poster: "/images/fourthVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 40,
            progressColor: "purple",
          },
          {
            poster: "/images/firstVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 80,
            progressColor: "blue",
          },
          {
            poster: "/images/secondVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 67,
            progressColor: "red",
          },
          {
            poster: "/images/thirdVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 30,
            progressColor: "green",
          },
          {
            poster: "/images/fourthVideo.png",
            description: "DAs Russia's military stalls",
            progressValue: 40,
            progressColor: "purple",
          },
        ],
      };
    } else {
      dummyData = {};
    }
    setSelectedCategory(dummyData);
  };

  const handleVideoClick = (video) => {
    onVideoClick(video);
  };
  return (
    <Box>
      <VideoPlayer />
      <CategoriesBar onCategoryClick={handleCategoryClick} />
      <Divider variant="middle" />
      <SelectedCategory
        data={selectedCategory}
        onVideoClick={handleVideoClick}
      />
    </Box>
  );
}
