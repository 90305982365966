import { postRequest } from "../connection/networkSpinner";
import { SnipperItem } from "../models/SnipperItem";
import { SnipperWheel } from "../models/SnipperWheel";

const spinnerApi = {
  wheel: "wheel/get_wheel",
  all: "wheel/get_all",
  item: "wheel_item/get_by_wheel_id",
  limit: "wheel_item/update_limit",
};
export const fetchSpinner = async (id) => {
  try {
    const {
      data: { content },
    } = await postRequest({
      endPoint: spinnerApi.wheel,
      body: {
        id: id,
      },
    });
    return SnipperWheel.fromJSON(content);
  } catch (e) {
    const { error } = e?.response?.data;
    return { error };
  }
};
export const allSpinner = async (id) => {
  try {
    const {
      data: { content },
    } = await postRequest({
      endPoint: spinnerApi.all,
    });
    return content?.map((e) => SnipperWheel.fromJSON(e));
  } catch (e) {
    const { error } = e?.response?.data;
    return { error };
  }
};

export const fetchSpinnerItem = async (id) => {
  try {
    const {
      data: { content },
    } = await postRequest({
      endPoint: spinnerApi.item,
      body: {
        wheelId: id,
      },
    });
    return content?.map((e) => SnipperItem.fromJSON(e));
  } catch (e) {
    const { error } = e?.response?.data;
    return { error };
  }
};

export const updateLimitItem = async ({ id, limit }) => {
  try {
    const {
      data: { content },
    } = await postRequest({
      endPoint: spinnerApi.limit,
      body: {
        id: id,
        limit: limit,
      },
    });

    return SnipperItem.fromJSON(content);
  } catch (e) {
    const error = e?.response?.state?.error;
    return { error };
  }
};
