export const SnipperWheel = {
  initialValue: () => initialValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data) => toMap(data),
};
const initialValue = () => ({
  name: "",
  background: "",
  button: "",
  rewardColor: "",
  size: "",
  frame: "",
});
const fromJSON = (data) => ({
  id: data?.id,
  name: data?.name,
  background: data?.background,
  button: data?.button,
  rewardColor: data?.rewardColor,
  size: data?.size,
  frame: data?.frame,
  logo: data?.logo,
});

const toMap = (data) => ({
  name: data?.name,
  background: data?.background,
  button: data?.button,
  rewardColor: data?.rewardColor,
  size: data?.size,
  frame: data?.frame,
  logo: data?.logo,
});
