function Emoji6() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.095 32.095">
      <g id="layer1" transform="translate(0)">
        <ellipse
          id="circle2611"
          cx="16.047"
          cy="16.047"
          rx="16.047"
          ry="16.047"
          transform="translate(0 0)"
          fill="#ffd42a"
        />
        <path
          id="path2613"
          d="M58.95,282.614A16.037,16.037,0,1,0,83.8,302.282q-.444.42-.918.806-.628.5-1.3.933t-1.392.8a16.021,16.021,0,0,1-1.464.655q-.748.291-1.522.506a16.1,16.1,0,0,1-3.158.544q-.566.04-1.134.04c-.533,0-1.068-.027-1.6-.08a16.1,16.1,0,0,1-4.65-1.187q-.739-.313-1.444-.7a16.024,16.024,0,0,1-1.367-.838q-.662-.453-1.277-.971T61.392,301.7a16.045,16.045,0,0,1-1.059-1.206q-.5-.628-.933-1.3t-.8-1.392q-.364-.715-.656-1.464t-.505-1.523q-.215-.773-.351-1.565t-.194-1.593q-.04-.566-.04-1.135,0-.8.08-1.6t.239-1.586a16.042,16.042,0,0,1,.4-1.555q.237-.767.55-1.507a16.038,16.038,0,0,1,.7-1.444q.064-.106.129-.21Z"
          transform="translate(-53.795 -278.33)"
          fill="#ffbc2a"
        />
        <path
          id="path2619"
          d="M57.468,289.673s.751,4.957,8.5,4.957"
          transform="translate(-50.151 -271.327)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="rect2633"
          d="M67.656,290.714a9.967,9.967,0,0,1-5.414,2.131,2.516,2.516,0,0,0,.148.328l2.264,3.92a2.522,2.522,0,0,0,3.454.926l.634-.367a2.524,2.524,0,0,0,.927-3.455Z"
          transform="translate(-45.414 -270.294)"
          fill="#f55"
        />
        <path
          id="path2621"
          d="M70.239,289.673s-.751,4.957-8.5,4.957"
          transform="translate(-45.916 -271.327)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <ellipse
          id="ellipse2623"
          cx="3.773"
          cy="3.718"
          rx="3.773"
          ry="3.718"
          transform="translate(18.17 5.909)"
          fill="#fff"
        />
        <ellipse
          id="ellipse2625"
          cx="3.773"
          cy="3.718"
          rx="3.773"
          ry="3.718"
          transform="translate(6.656 2.98)"
          fill="#fff"
        />
        <ellipse
          id="ellipse2627"
          cx="1.409"
          cy="1.389"
          rx="1.409"
          ry="1.389"
          transform="translate(7.023 6.642)"
          fill="#1a1a1a"
        />
        <ellipse
          id="ellipse2629"
          cx="1.409"
          cy="1.389"
          rx="1.409"
          ry="1.389"
          transform="translate(22.464 6.907)"
          fill="#1a1a1a"
        />
        <path
          id="path2638"
          d="M64.076,291.655l1.246,2.158"
          transform="translate(-43.594 -269.36)"
          fill="#f55"
        />
      </g>
    </svg>
  );
}

export default Emoji6;
