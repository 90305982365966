function ArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 13.722"
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M12,9.586,21.079.5A1.708,1.708,0,0,1,23.5.5a1.73,1.73,0,0,1,0,2.429L13.219,13.223a1.712,1.712,0,0,1-2.365.05L.5,2.94A1.715,1.715,0,0,1,2.923.511Z"
      />
    </svg>
  );
}

export default ArrowDown;
