import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React, { useState, useEffect } from "react";

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#25AAE1",
        },
      },
    },
  },
});

function CategoriesList({ imgSrc, category, onClick, isSelected }) {
  return (
    <Tab
      label={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
            height: "100%",
            transition: "0.5s all",
            color: isSelected ? "#25AAE1" : "#A9A9A9",
            backgroundColor: isSelected ? "#D5F3FF" : "transparent",
            outline: isSelected ? "1px solid #25AAE1" : "none",
            "&:hover": {
              backgroundColor: "#D5F3FF",
              outline: "1px solid #25AAE1",
              color: "#25AAE1",
            },
            padding: "0.5em",
            borderRadius: "5px",
          }}
          onClick={onClick}
        >
          <img src={imgSrc} alt="" style={{ width: "50px", height: "50px" }} />
          <Typography>{category}</Typography>
        </Box>
      }
    />
  );
}

export default function CategoriesBar({ onCategoryClick }) {
  let categories = [
    {
      image: "/images/Drama.svg",
      category: "Drama",
    },
    {
      image: "/images/Comedy.svg",
      category: "Comedy",
    },
    {
      image: "/images/Action.svg",
      category: "Action",
    },
    {
      image: "/images/Romance.svg",
      category: "Romance",
    },
    {
      image: "/images/Horror.svg",
      category: "Horror",
    },
    {
      image: "/images/Fantasy.svg",
      category: "Fantasy",
    },
    {
      image: "/images/Scifi.svg",
      category: "Sci-Fi",
    },
    {
      image: "/images/Thriller.svg",
      category: "Thriller",
    },
    {
      image: "/images/Western.svg",
      category: "Western",
    },
    {
      image: "/images/Adventure.svg",
      category: "Adventure",
    },
    {
      image: "/images/Animation.svg",
      category: "Animation",
    },
  ];

  const defaultCategory = categories[0].category;

  const handleCategoryClick = (category, index) => {
    setSelectedCategory(category);
    onCategoryClick(category);
    setValue(index);
  };

  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);

  useEffect(() => {
    onCategoryClick(defaultCategory);
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            maxWidth: "85vw",
            m: "0 auto",
          }}
        >
          {categories?.map((item, index) => (
            <CategoriesList
              key={index}
              imgSrc={item.image}
              category={item.category}
              onClick={() => handleCategoryClick(item.category, index)}
              isSelected={selectedCategory === item.category}
            />
          ))}
        </Tabs>
      </Box>
    </ThemeProvider>
  );
}
