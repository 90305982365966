function SortIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      viewBox="0 0 20.008 22.006"
    >
      <path
        id="up-down"
        d="M17,23a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1,1.42-1.42L16,19.59V2a1,1,0,0,1,2,0V19.59l2.29-2.3a1,1,0,1,1,1.42,1.42l-4,4A1,1,0,0,1,17,23ZM7,23a1,1,0,0,1-1-1V4.41L3.71,6.71A1,1,0,1,1,2.29,5.29l4-4a1,1,0,0,1,1.42,0l4,4a1,1,0,0,1-1.42,1.42L8,4.41V22A1,1,0,0,1,7,23Z"
        transform="translate(-1.996 -0.994)"
      />
    </svg>
  );
}

export default SortIcon;
