function Spinner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.109 19"
      stroke="none"
    >
      <g id="yoyo" transform="translate(-3.519 -8.153)">
        <path
          id="Path_15753"
          data-name="Path 15753"
          d="M10.787,27.153a7.272,7.272,0,0,0,.341-14.535v-.262a3.525,3.525,0,0,1,3.521-3.521h.792a3.525,3.525,0,0,1,3.521,3.521v4.687a2.288,2.288,0,1,0,4.576,0V11.168a1.43,1.43,0,1,0-.681,0v5.874a1.607,1.607,0,1,1-3.214,0V12.355a4.207,4.207,0,0,0-4.2-4.2h-.792a4.207,4.207,0,0,0-4.2,4.2v.262a7.272,7.272,0,0,0,.341,14.535ZM22.448,9.781a.749.749,0,1,1,.749.749.749.749,0,0,1-.749-.749ZM10.787,13.29A6.591,6.591,0,1,1,4.2,19.881a6.591,6.591,0,0,1,6.591-6.591Z"
          transform="translate(0 0)"
          stroke="none"
        />
        <path
          id="Path_15754"
          data-name="Path 15754"
          d="M18.752,47.961a4.929,4.929,0,1,0-4.929-4.929A4.929,4.929,0,0,0,18.752,47.961Zm0-9.176A4.247,4.247,0,1,1,14.5,43.033a4.247,4.247,0,0,1,4.247-4.247Z"
          transform="translate(-7.965 -23.152)"
          stroke="none"
        />
        <path
          id="Path_15755"
          data-name="Path 15755"
          d="M21.878,50.344a.341.341,0,0,0,.494.358l1.711-.892,1.711.892a.341.341,0,0,0,.493-.358l-.32-1.9,1.376-1.351a.341.341,0,0,0-.188-.581l-1.909-.284-.86-1.727a.341.341,0,0,0-.61,0l-.86,1.728-1.907.283a.341.341,0,0,0-.188.58L22.2,48.441Zm-.092-3.262,1.407-.209a.341.341,0,0,0,.255-.185l.634-1.274.634,1.274a.341.341,0,0,0,.255.185l1.407.209-1.015,1a.341.341,0,0,0-.1.3l.236,1.4-1.262-.658a.34.34,0,0,0-.315,0l-1.262.658.236-1.4a.341.341,0,0,0-.1-.3Z"
          transform="translate(-13.296 -27.948)"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default Spinner;
