import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { auth, interactive, authPoll, posts } from "./reducers";

const store = configureStore({
  reducer: {
    auth,
    interactive,
    authPoll,
    posts,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(thunkMiddleware);
  },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
