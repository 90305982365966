function EmptyStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.229"
      height="22.188"
      viewBox="0 0 23.229 22.188"
    >
      <path
        id="Icon_feather-star"
        data-name="Icon feather-star"
        d="M13.614,3l3.28,6.645,7.334,1.072-5.307,5.169,1.252,7.3-6.56-3.45-6.56,3.45,1.252-7.3L3,10.717l7.334-1.072Z"
        transform="translate(-2 -2)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}

export default EmptyStar;
