import { getFcmToken } from "../config/firebase";
import { DeviceUUID } from "device-uuid";
import { login } from "./../store/actions/authPoll/index";

export const AuthService = {
  getUserToken: () => getUserToken(),
};

const getUserToken = async () => {
  try {
    const firebaseToken = await getFcmToken();


    login({ token: firebaseToken });
  } catch (err) {
    let uuid = new DeviceUUID().get();

    login({ token: uuid });
  }
};
