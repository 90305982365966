function Emoji7() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.095 32.095">
      <g id="layer1" transform="translate(0)">
        <ellipse
          id="circle2642"
          cx="16.047"
          cy="16.047"
          rx="16.047"
          ry="16.047"
          transform="translate(0 0)"
          fill="#ffd42a"
        />
        <path
          id="path2644"
          d="M41.592,300.5a16.037,16.037,0,1,0,24.847,19.668q-.444.42-.918.806-.628.5-1.3.933a16.029,16.029,0,0,1-1.392.8q-.715.364-1.464.655t-1.522.507q-.774.214-1.566.351t-1.593.193q-.566.04-1.134.04c-.534,0-1.069-.027-1.6-.08a16.046,16.046,0,0,1-1.587-.24q-.787-.16-1.554-.4a16.038,16.038,0,0,1-1.507-.55q-.739-.313-1.444-.7t-1.367-.838q-.662-.453-1.277-.971a16.079,16.079,0,0,1-2.233-2.3q-.5-.629-.933-1.3a16.026,16.026,0,0,1-.8-1.392q-.364-.715-.656-1.464t-.505-1.523q-.215-.773-.351-1.565t-.194-1.593q-.04-.566-.04-1.135,0-.8.08-1.6t.239-1.586a16.136,16.136,0,0,1,.947-3.062q.313-.739.7-1.444.064-.105.129-.21Z"
          transform="translate(-36.437 -296.217)"
          fill="#ffbc2a"
        />
        <path
          id="path2688"
          d="M53.805,307.18s2.862-1.664,3.328-.067-4.193,4.06-9.651,4.06-6.722-.6-6.722-.6"
          transform="translate(-32.148 -290.344)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path2692"
          d="M40.686,306.993s1.73,3.727-.865,5.923"
          transform="translate(-33.08 -289.776)"
          fill="none"
          stroke="#1a1a1a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path2694"
          d="M48.093,310.714a6.236,6.236,0,0,0-4.565.118"
          transform="translate(-29.402 -286.477)"
          fill="none"
          stroke="#ffbc2a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path2696"
          d="M40.125,303.339s4.426-2.3,7.188.466"
          transform="translate(-32.778 -294.29)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <path
          id="path2698"
          d="M46.206,303.339s4.426-2.3,7.188.466"
          transform="translate(-26.745 -294.29)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.058"
        />
        <ellipse
          id="ellipse2700"
          cx="1.409"
          cy="1.389"
          rx="1.409"
          ry="1.389"
          transform="translate(11.226 7.238)"
          fill="#1a1a1a"
        />
        <ellipse
          id="ellipse2702"
          cx="1.409"
          cy="1.389"
          rx="1.409"
          ry="1.389"
          transform="translate(22.636 6.694)"
          fill="#1a1a1a"
        />
        <path
          id="path3051"
          d="M42.3,307.081a1.944,1.944,0,1,1-3.889,0c0-1.074.87-3.8,1.944-3.8S42.3,306.007,42.3,307.081Z"
          transform="translate(-34.474 -293.456)"
          fill="#009cb3"
        />
      </g>
    </svg>
  );
}

export default Emoji7;
