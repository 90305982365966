import {
  LOAD_POSTS,
  LOAD_POSTS_ERROR,
  LOAD_POSTS_LOADING,
  CHOOSE_POLL_OPTION,
  DELETE_CHOICE_POLL_OPTION,
  UPDATE_POLL_OPTION,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: {
    byId: {},
    allIds: [],
  },
  count: 0,
  thisPage: 0,
  hasMore: true,
};
const postsReducer = createReducer(initialState, (builder) => {
  builder
   
    .addCase(CHOOSE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.data?.byId[payload?.postId];

      if (postFound?.type?.poll) {
        s.data.byId[payload?.postId].type.poll = payload?.poll;
      }
    })
    .addCase(UPDATE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.data?.byId[payload?.postId];

      if (postFound?.type?.poll) {
        const questionIndex = s.data.byId[
          payload?.postId
        ].type.poll?.questions?.findIndex(
          (question) => question.id === payload?.questionId
        );

        const optionIndex = s.data.byId[payload?.postId].type.poll.questions[
          questionIndex
        ]?.options?.findIndex((option) => option.id === payload?.option?.id);

        s.data.byId[payload?.postId].type.poll.questions[questionIndex].options[
          optionIndex
        ] = payload?.option;
      }
    })
    .addCase(DELETE_CHOICE_POLL_OPTION, (s, { payload }) => {
      let postFound = s?.data?.byId[payload?.postId];
      if (postFound?.type?.poll) {
        const qIndex = s.data.byId[
          payload?.postId
        ].type.poll?.questions?.findIndex((e) => e?.id === payload?.questionId);

        s.data.byId[payload?.postId].type.poll.questions[
          qIndex
        ].myChosenOption = null;
      }
    })


    .addCase(LOAD_POSTS_LOADING, (s) => {
      s.loading = true;
      s.error = false;
    })
    .addCase(LOAD_POSTS_ERROR, (s, { payload }) => {
      s.loading = false;
      s.error = payload;
    })
    .addCase(LOAD_POSTS, (s, { payload }) => {
      s.data.byId =
        payload?.thisPage === 1
          ? payload.byId
          : { ...s.data?.byId, ...payload?.byId };

      if (payload?.sortBy === "date") {
        if (payload?.thisPage === 1) {
          s.data.allIds = payload.allIds;
        } else {
          Object.entries(payload.allIds).forEach(([key, value]) => {
            s.data.allIds[key] = [...(s.data.allIds[key] || []), value];
          });
        }
      } else {
        s.data.allIds =
          payload?.thisPage === 1
            ? payload.allIds
            : [...s.data?.allIds, ...payload?.allIds];
      }
      s.thisPage = payload.thisPage;
      s.hasMore = payload.hasMore;
      s.count = payload.count;
      s.loading = false;
    })
 ;
});

export default postsReducer;
