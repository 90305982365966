export default function NewArrivals() {
  return (
    <svg id="new" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 49.992">
      <path
        id="Path_15804"
        data-name="Path 15804"
        d="M50.718,25.39l-5.459-4.677c-.056-.2-.115-.42-.174-.645a20.721,20.721,0,0,0-1.343-3.945V9.067a.806.806,0,0,0-.806-.806H35.884a18.629,18.629,0,0,0-3.816-1.524l-5.5-5.5a.83.83,0,0,0-1.14,0l-5.5,5.5A18.864,18.864,0,0,0,16.116,8.26H9.065a.806.806,0,0,0-.806.806v7.056a20.668,20.668,0,0,0-1.342,3.943c-.06.223-.119.44-.175.645l-5.46,4.677a.806.806,0,0,0-.046,1.182l5.5,5.5a18.883,18.883,0,0,0,1.524,3.816v7.052a.806.806,0,0,0,.806.806h7.05a22.011,22.011,0,0,0,4.1,1.8l5.219,5.218a.806.806,0,0,0,1.14,0l5.218-5.218a22,22,0,0,0,4.1-1.8h7.05a.806.806,0,0,0,.806-.806V35.886a18.939,18.939,0,0,0,1.524-3.816l5.5-5.5a.806.806,0,0,0-.046-1.182Zm-6.74,5.687a.806.806,0,0,0-.206.35,17.244,17.244,0,0,1-1.542,3.861.806.806,0,0,0-.1.392v6.452H35.678a.807.807,0,0,0-.392.1,20.249,20.249,0,0,1-4.143,1.824.794.794,0,0,0-.351.206L26,49.056l-4.794-4.794a.806.806,0,0,0-.35-.206,20.25,20.25,0,0,1-4.141-1.823.806.806,0,0,0-.393-.1H9.871V35.68a.807.807,0,0,0-.1-.392,17.2,17.2,0,0,1-1.542-3.86.8.8,0,0,0-.206-.35l-5.03-5.03,4.984-4.274a.806.806,0,0,0,.252-.392c.081-.279.161-.582.247-.9a18.549,18.549,0,0,1,1.3-3.767.806.806,0,0,0,.1-.39V9.873h6.452a.807.807,0,0,0,.392-.1,17.214,17.214,0,0,1,3.86-1.542.794.794,0,0,0,.351-.206L26,2.949l5.075,5.075a.792.792,0,0,0,.35.206,17.282,17.282,0,0,1,3.861,1.542.806.806,0,0,0,.392.1h6.452v6.452a.807.807,0,0,0,.1.392,18.529,18.529,0,0,1,1.3,3.77l.242.9a.806.806,0,0,0,.252.392L49,26.05Z"
        transform="translate(-1 -1.011)"
      />
      <path
        id="Path_15805"
        data-name="Path 15805"
        d="M26.827,42.805a.806.806,0,0,0,.792-1.346l-8.145-8.145-1.14,1.141L24.278,40.4,15.864,38a.806.806,0,0,0-.791,1.346l8.145,8.145,1.14-1.14L18.414,40.4Z"
        transform="translate(-3.678 -7.263)"
      />
      <path
        id="Path_15806"
        data-name="Path 15806"
        d="M28.369,27.381l-1.14-1.14-4.073,4.073a.806.806,0,0,0,0,1.14l7.329,7.334a.806.806,0,0,0,1.14,0L35.7,34.715l-1.14-1.14-3.5,3.5-2.933-2.933,2.689-2.689-1.14-1.14L26.984,33l-2.118-2.118Z"
        transform="translate(-5.242 -5.894)"
      />
      <path
        id="Path_15807"
        data-name="Path 15807"
        d="M34.19,18.027a.806.806,0,0,0-.152.931l3.53,7.06-7.249-4.833-.894,1.342L39.2,29.044a.806.806,0,0,0,1.172-1.031L36.567,20.4l7.612,3.806a.806.806,0,0,0,1.032-1.169L38.69,13.262l-1.342.894,4.833,7.249-7.06-3.53a.806.806,0,0,0-.931.151Z"
        transform="translate(-6.501 -3.382)"
      />
      <path
        id="Path_15808"
        data-name="Path 15808"
        d="M18.191,18.191a14.113,14.113,0,0,1,13.172-3.8l.358-1.573a15.789,15.789,0,0,0-18.9,18.9l1.573-.356a14.113,14.113,0,0,1,3.8-13.173Z"
        transform="translate(-3.211 -3.22)"
      />
      <path
        id="Path_15809"
        data-name="Path 15809"
        d="M44.975,28a14.174,14.174,0,0,1-16.97,16.971l-.358,1.573a15.785,15.785,0,0,0,18.9-18.9Z"
        transform="translate(-6.157 -6.166)"
      />
      <path
        id="Path_15810"
        data-name="Path 15810"
        d="M8.613,48H7v6.452a.806.806,0,0,0,.806.806h6.452V53.645H8.613Z"
        transform="translate(-2.161 -10.105)"
      />
      <path
        id="Path_15811"
        data-name="Path 15811"
        d="M18,55h1.613v1.613H18Z"
        transform="translate(-4.29 -11.46)"
      />
      <path
        id="Path_15812"
        data-name="Path 15812"
        d="M7,44H8.613v1.613H7Z"
        transform="translate(-2.161 -9.331)"
      />
    </svg>
  );
}
