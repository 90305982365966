import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box } from "@mui/material";
import Crown from "../../../SVGs/Crown";
import BroadcastRadio from "../../../SVGs/BroadcastRadio";
import Spinner from "../../../SVGs/Spinner";
import Competition from "../../../SVGs/Competition";
import Challenge from "../../../SVGs/Challenge";
import Survey from "../../../SVGs/Survey";
import CreateChannel from "../../../SVGs/CreateChannel";
import Quiz from "../../../SVGs/Quiz";
import InteractiveBook from "../../../SVGs/InteractiveBook";
import Ask from "../../../SVGs/Ask";
import SendReact from "../../../SVGs/SendReact";
import RequestIcon from "../../../SVGs/RequestIcon";

const ColorlibConnector = styled(StepConnector)(({ theme }) => {
  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 35,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#D5F3FF",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#25AAE1",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  };
});

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eeeeee",
  zIndex: 1,
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#D5F3FF",
    boxShadow: "0 4px 10px 0 rgba(37,170,225,.5)",
    transition: "0.5s all",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#25AAE1",
    transition: "0.5s all",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const createCustomIcon = (IconComponent, active, completed) => (
    <Box
      sx={{
        fill: active ? "#25AAE1" : completed ? "#fff" : "#7a7a7a",
        stroke: active ? "#25AAE1" : completed ? "#fff" : "#7a7a7a",
        width: "25px",
      }}
    >
      <IconComponent />
    </Box>
  );

  const icons = {
    1: createCustomIcon(BroadcastRadio, active, completed),
    2: createCustomIcon(Spinner, active, completed),
    3: createCustomIcon(Competition, active, completed),
    4: createCustomIcon(Challenge, active, completed),
    5: createCustomIcon(Survey, active, completed),
    6: createCustomIcon(CreateChannel, active, completed),
    7: createCustomIcon(Quiz, active, completed),
    8: createCustomIcon(InteractiveBook, active, completed),
    9: createCustomIcon(Ask, active, completed),
    10: createCustomIcon(SendReact, active, completed),
    11: createCustomIcon(RequestIcon, active, completed),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  "Broadcast Radio",
  "Spinner",
  "Competition",
  "Challenge",
  "Survey",
  "Create Channel",
  "Quiz",
  "Interactive Book",
  "Ask",
  "Request",
  "Send React",
];

export default function CustomizedSteppers({ activeStep, progress }) {
  return (
    <Box
      sx={{
        maxWidth: "100%",
        overflow: "auto",
        padding: { xs: "1em", sm: "1.5em" },
        display: "flex",
        flexShrink: 0,
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={progress === 0 ? -1 : activeStep}
        connector={<ColorlibConnector />}
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          flex: "1",
        }}
      >
        {steps.map((label, index) => {
          const active = index === activeStep && progress !== 0;
          const completed = index < activeStep;
          return (
            <Step key={label}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "5px",
                }}
              >
                <Crown active={active} completed={completed} />
              </Box>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
        <Box
          sx={{
            position: "absolute",
            height: 3,
            backgroundColor: "transparent",
            width: { xs: "95%", lg: "90%" },
            zIndex: 0,
            top: 35,
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: 3,
              width: `${progress + progress * (7 / 100)}%`,
              backgroundColor: "#25AAE1",
              zIndex: "-1",
              transition: "width 0.5s",
              maxWidth: "100%",
            }}
          ></Box>
        </Box>
      </Stepper>
    </Box>
  );
}
