function Emoji2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.095 32.095">
      <g id="layer1" transform="translate(0)">
        <ellipse
          id="circle2488"
          cx="16.047"
          cy="16.047"
          rx="16.047"
          ry="16.047"
          transform="translate(0 0)"
          fill="#ffd42a"
        />
        <path
          id="path2490"
          d="M23.629,282.614a16.037,16.037,0,1,0,24.847,19.668q-.444.42-.918.806a16.034,16.034,0,0,1-1.3.933q-.676.434-1.392.8a16.021,16.021,0,0,1-1.464.655q-.748.291-1.522.506a16.1,16.1,0,0,1-3.158.544q-.566.04-1.134.04c-.533,0-1.068-.027-1.6-.08a16.057,16.057,0,0,1-7.461-2.723q-.662-.453-1.277-.971T26.071,301.7q-.559-.576-1.059-1.206a16.023,16.023,0,0,1-.933-1.3q-.433-.676-.8-1.392t-.656-1.464q-.291-.748-.505-1.523t-.351-1.565q-.137-.791-.194-1.593-.04-.566-.04-1.135a16.043,16.043,0,0,1,.08-1.6q.08-.8.239-1.586a16.043,16.043,0,0,1,.4-1.555q.237-.767.55-1.507t.7-1.444q.064-.106.129-.21Z"
          transform="translate(-18.474 -278.33)"
          fill="#ffbc2a"
        />
        <path
          id="path2504"
          d="M42.555,288.727a10.7,10.7,0,1,1-21.4,0h21.4Z"
          transform="translate(-15.81 -272.265)"
          fill="#1a1a1a"
        />
        <path
          id="path2511"
          d="M24.891,8.66H21.159a10.7,10.7,0,0,0,21.4,0H38.822v1.892A2.443,2.443,0,0,1,36.375,13H27.34a2.443,2.443,0,0,1-2.448-2.447Z"
          transform="translate(-15.81 7.801)"
          fill="#1a1a1a"
        />
        <path
          id="path2506"
          d="M23.032,8.66v1.892A2.445,2.445,0,0,0,25.48,13h9.036a2.44,2.44,0,0,0,2.447-2.447V8.66H23.032Z"
          transform="translate(-13.951 7.801)"
          fill="#fff"
        />
        <path
          id="path2518"
          d="M31.571,294.372q-.016-.069-.035-.137a2.72,2.72,0,0,0-.109-.283,2.789,2.789,0,0,0-.14-.272,2.914,2.914,0,0,0-.172-.259,3.028,3.028,0,0,0-.2-.243,3.169,3.169,0,0,0-.228-.224q-.12-.107-.253-.2t-.275-.181q-.143-.085-.295-.156t-.311-.13q-.159-.058-.325-.1t-.335-.074q-.169-.03-.342-.045t-.345-.016q-.122,0-.244.008-.173.011-.343.036t-.337.065q-.166.04-.327.094t-.316.122q-.154.067-.3.148t-.281.174q-.135.093-.26.2t-.235.219a3.067,3.067,0,0,0-.21.238,2.945,2.945,0,0,0-.18.254,2.831,2.831,0,0,0-.15.269,2.736,2.736,0,0,0-.118.28q-.038.11-.066.223a9.02,9.02,0,0,0,6.732,0Z"
          transform="translate(-12.159 -268.971)"
          fill="#f55"
        />
        <path
          id="path2520"
          d="M22.51,283.553a1,1,0,0,0-.218,1.961c-.213-.057-.038-.01.085.055s.294.16.494.273c.4.226.914.521,1.412.816.272.161.285.171.531.319-.247.148-.259.158-.531.319-.5.3-1.012.6-1.412.822-.2.113-.371.207-.494.272s-.3.112-.085.055a1,1,0,1,0,.514,1.922,1.562,1.562,0,0,0,.49-.21c.158-.082.346-.184.557-.3.423-.239.938-.541,1.443-.84,1.01-.6,1.972-1.183,1.972-1.183h0l.006,0,.018-.012a1,1,0,0,0-.16-1.768c-.118-.072-.9-.542-1.838-1.1-.505-.3-1.021-.6-1.443-.84-.211-.119-.4-.222-.557-.3a1.553,1.553,0,0,0-.49-.214,1,1,0,0,0-.3-.035Z"
          transform="translate(-15.47 -277.399)"
          fill="#1a1a1a"
        />
        <path
          id="path2524"
          d="M33.634,283.553a1,1,0,0,0-.265.035,1.564,1.564,0,0,0-.491.214c-.158.082-.345.185-.556.3-.423.238-.938.541-1.443.84-.957.566-1.776,1.062-1.87,1.118a1,1,0,0,0-.225,1.679q.034.028.071.054l.025.016.026.018s.963.585,1.974,1.183c.505.3,1.021.6,1.443.84.211.119.4.222.556.3a1.576,1.576,0,0,0,.491.21,1,1,0,1,0,.543-1.916c.087.023-.007-.006-.111-.061-.123-.064-.3-.159-.5-.272-.4-.226-.909-.526-1.408-.822-.272-.161-.285-.171-.532-.319.247-.148.26-.158.532-.319.5-.3,1.008-.59,1.408-.816.2-.113.375-.208.5-.273s.3-.112.085-.055a1,1,0,0,0-.252-1.961Z"
          transform="translate(-8.606 -277.398)"
          fill="#1a1a1a"
        />
      </g>
    </svg>
  );
}

export default Emoji2;
