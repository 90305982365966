import { Question } from "./Question";
import { Vote } from "./Vote";

export const PollSummary = {
  initialValue: () => initialValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data) => toMap(data),
};

const initialValue = () => ({
  currentQuestion: Question.initialValue(),
  votes: [],
});

const fromJSON = (data) => ({
  currentQuestion: Question.fromJSON(data?.currentQuestion),
  votes: data?.votes?.map((e) => Vote.fromJSON(e)),
});

const toMap = (data) => ({
  currentQuestion: Question.toMap(data?.currentQuestion),
  votes: data?.votes?.map((e) => Vote.toMap(e)),
});
