const ActionType = {
  SWITCH_QUESTION: "SWITCH_QUESTION",
  URL: "URL",
  API_CALL: "API_CALL",
};

export const InteractiveVideoAction = {
  ActionType: ActionType,
  initialValue: () => initialValue(),
  fromJSON: (data, width, height) => fromJSON(data, width, height),
  toMap: (data) => toMap(data),
};

const initialValue = () => ({
  option: "",
  optional_index: "",

  delay: "",
  show: false,

  x: "",
  y: "",

  type: "",
  url: "",
});

const fromJSON = (data, width, height) => ({
  title: data?.option,
  value: data?.optionalIndex,

  delay: data?.delay,

  x: data?.x,
  y: data?.y,

  xFactor: data?.x / width,
  yFactor: data?.y / height,

  type: _getType(data?.type),

  show: false,
});

const toMap = (data) => ({
  option: data?.title,
  optional_index: data?.value,

  delay: data?.delay,
  show: false,

  x: data?.x,
  y: data?.y,

  type: data?.type,
  url: data?.url,
});

const _getType = (type) => {
  switch (type) {
    case "1":
      return ActionType.SWITCH_QUESTION;
    case "2":
      return ActionType.URL;
    case "3":
      return ActionType.API_CALL;
    default:
      return ActionType.SWITCH_QUESTION;
  }
};
