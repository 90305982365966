import { createReducer } from "@reduxjs/toolkit";
import { LOG_IN, LOG_OUT, SET_SESSION } from "../actions/types";
import { axios, socket } from "../../config/network";

const initialState = {
  isLoggedIn: false,
  token: null,
};

const authPollReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(LOG_IN, (state, { payload }) => {
      setSession(payload?.token);
      state.isLoggedIn = true;
      state.token = payload?.token;
    })
    .addCase(LOG_OUT, (state) => {
      setSession();
      state.isLoggedIn = false;
    })
    .addCase(SET_SESSION, (state, { payload }) => {
      setSession(payload?.token);

      state.isLoggedIn = payload?.isLoggedIn;
      state.token = payload?.token;
    });
});

const setSession = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `${token}`;

    socket.io.opts.extraHeaders = {
      token: token,
    };
    socket.disconnect().connect();
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export default authPollReducer;
