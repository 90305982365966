import baseAxios from "axios";
import { io } from "socket.io-client";
import { BASE_URL, SOCKET_URL, REQUEST_TIMEOUT } from "./constants";

export const axios = baseAxios.create({
  baseURL: BASE_URL,
  timeout: REQUEST_TIMEOUT,
});

axios.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject((error.response && error.response.data) || "Wrong Services")
);

export const socket = io(SOCKET_URL);
