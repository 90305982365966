function HalfStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 23.032 22"
      stroke="none"
    >
      <path
        id="Icon_awesome-star-half-alt"
        data-name="Icon awesome-star-half-alt"
        d="M21.852,7.37l-6.289-.916L12.752.765a1.38,1.38,0,0,0-2.471,0L7.469,6.453l-6.29.915A1.375,1.375,0,0,0,.417,9.714l4.55,4.426L3.89,20.391A1.378,1.378,0,0,0,5.249,22a1.358,1.358,0,0,0,.639-.161l5.627-2.951,5.626,2.952a1.375,1.375,0,0,0,2-1.448l-1.076-6.252,4.551-4.425a1.375,1.375,0,0,0-.762-2.346Zm-5.231,5.294-.779.757.184,1.069.839,4.875-4.388-2.3-.962-.5V2.928l2.193,4.438.48.972,1.075.156,4.908.715-3.551,3.454Z"
        transform="translate(0)"
      />
    </svg>
  );
}

export default HalfStar;
