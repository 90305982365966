import { axios, socket } from "../config/network";
import { Poll } from "../models/Poll";
import { PollSummary } from "../models/PollSummary";
import { Vote } from "../models/Vote";

export const PollsService = {
  fetchPoll: (id) => fetchPoll(id),
  joinRoom: (id, videoDetailsCallback, actionSelectedCallback) =>
    joinRoom(id, videoDetailsCallback, actionSelectedCallback),
  leaveRoom: () => leaveRoom(),
  listenToQuestionChange: (nextQuestionCallBack) =>
    listenToQuestionChange(nextQuestionCallBack),
  selectAction: (pollId, questionId, actionId) =>
    selectAction(pollId, questionId, actionId),
  stopQuestionVoting: (pollId, currentQuestion) =>
    stopQuestionVoting(pollId, currentQuestion),
  goToQuestion: (pollId, newQuestion) => goToQuestion(pollId, newQuestion),
};

const fetchPoll = async (id) => {
  try {
    // return await new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(Poll.fromJSON(poll));
    //   }, 2000);
    // });

    const response = await axios.get("/conversation/senario", {
      params: {
        senarioId: id,
      },
    });

    const { data } = response?.data;

    return Poll.fromJSON(data);
  } catch (err) {
    throw new Error("Something went wrong, please try again later");
  }
};

const joinRoom = async (id, videoDetailsCallback, actionSelectedCallback) => {
  try {
    socket.emit("join_video_room", {
      videoId: id,
    });

    socket.on("video_room_details", (data) => {
      videoDetailsCallback && videoDetailsCallback(PollSummary.fromJSON(data));
    });

    socket.on("action_selected", (data) => {
      actionSelectedCallback &&
        actionSelectedCallback(data?.map((e) => Vote.fromJSON(e)));
    });
  } catch (err) {
    throw new Error("Something went wrong, please try again later");
  }
};

const leaveRoom = async () => {
  try {
    socket.removeAllListeners("action_selected");
  } catch (err) {
    throw new Error("Something went wrong, please try again later");
  }
};

const listenToQuestionChange = async (nextQuestionCallBack) => {
  try {
    socket.on("next_question", (data) => {
      nextQuestionCallBack && nextQuestionCallBack(data?.nextQuestion?.id);
    });
  } catch (err) {
    throw new Error("Something went wrong, please try again later");
  }
};
const stopQuestionVoting = async (pollId, currentQuestion) => {
  try {
    socket.emit("stop_vote", {
      videoId: pollId,
      questionId: currentQuestion,
    });
  } catch (err) {
    throw new Error("Something went wrong, please try again later");
  }
};

const goToQuestion = async (pollId, newQuestion) => {
  try {
    socket.emit("next_question", {
      videoId: pollId,
      nextQuestionId: newQuestion,
    });
  } catch (err) {
    throw new Error("Something went wrong, please try again later");
  }
};

const selectAction = async (pollId, questionId, actionId) => {
  try {
    socket.emit("select_action", {
      videoId: pollId,
      questionId: questionId,
      actionId: actionId,
    });
  } catch (err) {
    throw new Error("Something went wrong, please try again later");
  }
};
