import { Answer } from "./Answer";

export const Question = {
  initialValue: () => initialValue(),
  fromJSON: (data) => fromJSON(data),
  toMap: (data) => toMap(data),
};

const initialValue = () => ({
  id: "",
  title: "",
  countdownTime: 0,

  answers: [],
});

const fromJSON = (data) => ({
  id: data?.id,
  title: data?.questionText,
  countdownTime: data?.countdown,
  // countdownTime: 10,

  answers: data?.answers?.map((e) => Answer.fromJSON(e)),
});

const toMap = (data) => ({
  id: data?.id,
  title: data?.title,
  countdown: data?.countdownTime,
  answers: data?.answers?.map((e) => Answer.toMap(e)),
});
